import { combineValidators, isRequired } from "revalidate";
import React, { useContext, useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import {IBookedVisitors, IEditRV} from "../../../models/VisitorModel";
import { observer } from "mobx-react-lite";
import { Button, Form, Grid, Checkbox, Header, Icon, Segment } from "semantic-ui-react";
import {RootStoreContext} from "../../../stores/rootStore";
import RemoveRegularVisitor from './RemoveRV';
import TextInput from "../../../common/form/TextInput";
import SelectInput from "../../../common/form/SelectInput";
import ErrorMessage from "../../layout/ErrorMessage";
import { FORM_ERROR } from "final-form";



const validate =  combineValidators(
    {
        name: isRequired("name"),
        email: isRequired("emails"),
        company: isRequired("company"),

    }
);

interface IProps{
    Aid: IEditRV;
    isViewDetails: boolean;
}

const ViewRV: React.FC<IProps> =({Aid, isViewDetails}) =>{

    const rootStore = useContext(RootStoreContext);
    const { Edit, updateDataRV, handleUpdateClick,RemoveRv, resetUpdateRV, ResendConfirmationEmail, removingRepLoadingRV} = rootStore.rvStore;
    const {openRvModal} = rootStore.modalStore
    const {SignFlag, EnterTemperature, badgeNumFlag, setFlags, handleUpdateBadgeNumFlag,handleUpdateEnterTemperature,handleUpdateSignFlag} = rootStore.rvStore
    
   

    const handleEditRv = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      data: IEditRV
    ) => {
      event.preventDefault();
      handleUpdateClick();
    };
    
       const handleRemoveRv =() => {

       // RemoveRv();
       }//(event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      //   data: IBookedVisitors
      // ) => {
      //   event.preventDefault();
      //   openRvModal(data.id!, data.tenantId!)
     // };
    
      const handleResendConfirmationEmailRep = () =>{
    
      };
      
      useEffect(() => {


        setFlags(Aid.signFlag, Aid.badgeNumFlag, Aid.enterTemperature);
  
        return () => {
          resetUpdateRV();
        };
      }, [resetUpdateRV]);


return(
    <div>
        <RemoveRegularVisitor />
      <Grid centered className ="Rvgrid">
        <Grid.Column width={16} stretched ={true}>
        <Header as="h4" attached="top">
            {isViewDetails ? (
              <div>
                Edit <u>{Aid?.name}</u> Details
              </div>
            ) : (
              <div>
                View <u>{Aid?.name}</u> Details
              </div>
            )}

          </Header>
          <Segment clearing>
            <FinalForm
              onSubmit={(values: IEditRV) =>
                Edit(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }))
              }
              initialValues={Aid}
              validate={validate}  
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error loading={submitting}>

                  <Form.Group widths="equal">
                     <Field
                        label="Reference No"
                        name="meetingreference"
                        placeholder="RV Number"
                        component={TextInput}
                        readOnly={true}
                        defaultValue = {Aid?.meetingReference}
                      />
                      <Field
                        label="Company"
                        name="company"
                        placeholder="Company"
                        component={TextInput}
                       readOnly={!updateDataRV}
                      />   
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Field
                      label="Name"
                      name="name"
                      placeholder="Name"
                      component={TextInput}
                      readOnly={!updateDataRV}
                      //defaultValue = {Aid.name}
                    />
                   
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Field
                      label="Email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      component={TextInput}
                      readOnly={!updateDataRV}
                    />
                  </Form.Group>


                    <Form.Group widths = "equal">

                      {!updateDataRV ?(
                        <Checkbox
                         toggle
                         readOnly = {!updateDataRV}
                         name = "badgeNumFlag"
                         checked = {Aid?.badgeNumFlag}
                         label="Enter Badge Number?" />
                      ) : (
                        <Checkbox
                        
                        onClick = {handleUpdateBadgeNumFlag}
                        toggle
                        name = "badgeNumFlag"
                        checked = {badgeNumFlag}
                        label="Enter Badge Number?" />

                      )}

                        {!updateDataRV ?(
                        <Checkbox
                         toggle
                         readOnly = {!updateDataRV}
                         name = "SignFlag"
                         checked = {Aid?.signFlag}
                         label="SignFlag?" />
                      ) : (
                        <Checkbox
                        
                        onClick = {handleUpdateSignFlag}
                        toggle
                        name = "SignFlag"
                        checked = {SignFlag}
                        label="SignFlag?" />

                      )}

                        {!updateDataRV ?(
                        <Checkbox
                         toggle
                         readOnly = {!updateDataRV}
                         name = "EnterTemperature"
                         checked = {Aid?.enterTemperature}
                         label="Enter Temperature?" />
                      ) : (
                        <Checkbox
                        
                        onClick = {handleUpdateEnterTemperature}
                        toggle
                        name = "EnterTemperature"
                        checked = {EnterTemperature}
                        label="Enter Temperature?" />

                      )}

                </Form.Group>

                  <Field
                    name="id"
                    type='hidden'
                    defaultValue={Aid?.id}   
                    component={TextInput}
                  />  
                
                  <Grid columns="2">
                    <Grid.Column>
                      <Button
                        fluid
                        color="red"
                        content="Remove RegularVisitor."
                        icon="trash"
                        compact
                        labelPosition="left"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => handleRemoveRv()}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      
                        <div>
                          <Button
                            fluid
                            color="yellow"
                            content="Send QR Code"
                            icon="mail"
                            compact
                            onClick={ () => ResendConfirmationEmail(Aid.meetingReference)}
                            labelPosition="left"
                          />
                        </div>
                    
                    </Grid.Column>
                  </Grid>


                  {updateDataRV ? (
                    <Button
                      fluid
                      icon="save"
                      loading={submitting}
                      disabled={(invalid && !dirtySinceLastSubmit)}
                      positive
                      labelPosition="left"
                      content="Save"
                    />
                  ) : (
                    <Button
                      fluid
                      icon="edit"
                      loading={submitting}
                      // disabled={
                      //   (invalid && !dirtySinceLastSubmit) || pristine
                      // }
                      primary
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleEditRv(event, Aid!)}
                      labelPosition="left"
                      content="Update"
                    />
                  )}
                    {submitError && !dirtySinceLastSubmit && (
                    <ErrorMessage error={submitError} />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
);
}

export default observer(ViewRV);