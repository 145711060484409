import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Modal } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";

const PartnerClockInModal = () => {
  const rootStore = useContext(RootStoreContext);
  const { partnerClockInModal, signInPartner, signInOrganization, partnerData ,partnerCloseClockInModal} = rootStore.partnerStore;

  return (
    <Modal className="partner-clock-in-modal" open={partnerClockInModal} size="mini">
      <Modal.Header>Check-In</Modal.Header>
      <Modal.Content>
        <h3>
          Thank you <u>{signInPartner}</u> from{" "}
          <u>{signInOrganization}</u>.
        </h3>
        <h3>You have now checked in.</h3>
      </Modal.Content>

      <div className="modal-action">
        <Button
        fluid
          content="Close"
          color="green"
          onClick={partnerCloseClockInModal}
        />
      </div>
    </Modal>
  );
};

export default observer(PartnerClockInModal);
