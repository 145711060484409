import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Image } from "semantic-ui-react";
import logo from "../../assets/visIT_logo.png";
import { history } from "../..";
import { RootStoreContext } from "../../stores/rootStore";
import Clock from "react-live-clock";
import { observer } from "mobx-react-lite";
import VisitorCodeOption from "../Modals/VisitorCodeOption";
import VisitorCodeOptionRegularVisitor from "../Modals/VisitorCodeOptionRegularVisitor";
import PartnerClockInModal from "../Modals/PartnerClockInModal";
import PartnerClockOutModal from "../Modals/PartnerClockOutModal";
import QrReader from "react-qr-reader";
import QRCode from "../Modals/QRCode";
import QRCheckout from "../Modals/QRCheckout";
import SignoutModal from "../Modals/SignoutModal";
import SignInValidationModal from "../Modals/SignInValidationModal";
import SignOutValidationModal from "../Modals/SignOutValidationModal";
import DepotSignInWarning from "../Modals/DepotSignInWarning";
import CheckoutMessage from "../Modals/CheckoutMessage";
import LoadingComponent from "../Modals/LoadingComponents";

const VisitorPortal = (props: any) => {
  const rootStore = useContext(RootStoreContext);
  // const [company, setCompany] = useState("");
  const [depotNameFlag, setDepotNameFlag] = useState(false);
  const [depotArr] = useState(["l2", "L2", "BT1", "BT5", "bt1", "bt5"]);
  const { depot, getDepot } = rootStore.depotStore;

  const [clockset, setClockState] = useState<string>();

  const {
    openVisitorCodeOptionModal,
    openVisitorCodeOption,
    QRCodeOptionModal,
    openVisitorQRCodeOption,
    openVisitorCodeOptionRegular,
    openSignInValidationModal,
    openSignOutValidationModal,
    hasReference,
    checkoutWindow,
    DepotSignInWarningModal,
    DepotSignInWarningModalValidation,
    hasQRReference,
    hasReferenceRegular,
    SearchQRInfo,
    hasCheckoutRef,
    hasSignoutModal,
    SignoutModalQR,
    hasSignInValidation,
    hasSignOutValidation,
    loading
  } = rootStore.visitorStore;

  const { match } = props;
  let { tenantName, depotCode } = match.params;

  // const intervalID = setInterval(() => {
  //   const date = new Date();
  //   setClockState(date.toLocaleTimeString());
  //   return () => {
  //     clearInterval(intervalID);
  //   };
  // });




  useEffect(() => {
    if (depotCode) {
      getDepot(tenantName, depotCode);
    }

    if (depotArr.includes(depotCode)) setDepotNameFlag(true);

    const time = () => {
      const event = new Date();
      setClockState(event.toLocaleTimeString());
    };
    const intervalId = setInterval(time);
  
    return () => {
      clearInterval(intervalId);
    };
  
    
  }, [depotCode, getDepot, depotArr, tenantName, setDepotNameFlag]);

  //console.log("loading status", loading)

  if(loading) return <LoadingComponent content ="Please Wait"/>

  return (
    <div>
      <QRCode hasQRReference={hasQRReference} open={openVisitorQRCodeOption} />

      <VisitorCodeOption
        hasReference={hasReference}
        open={openVisitorCodeOption}
      />
      <SignoutModal
        hasSignOutReference={hasSignoutModal}
        open={SignoutModalQR}
      />

      <QRCheckout hasCheckoutRef={hasCheckoutRef} open={QRCodeOptionModal} />
      <CheckoutMessage open={checkoutWindow} />

      <PartnerClockInModal />
      <PartnerClockOutModal />
      <VisitorCodeOptionRegularVisitor
        hasReferenceRegular={hasReferenceRegular}
        open={openVisitorCodeOptionRegular}
      />
       <DepotSignInWarning
      open= {DepotSignInWarningModal}
      accept={DepotSignInWarningModalValidation}
      />
      

      <SignInValidationModal
        hasSignInValidation={hasSignInValidation}
        open={openSignInValidationModal}
      />

      <SignOutValidationModal
        hasSignOutValidation={hasSignOutValidation}
        open={openSignOutValidationModal}
      />

      <Grid columns={2} centered style={{ marginTop: 5 }}>
        <Grid.Row>
          <Grid.Column>
            <Image
              centered
              src={logo}
              style={{
                marginTop: 20,
                marginBottom: 25,
              }}
            />
            <br />
          </Grid.Column>
        </Grid.Row>

        <div className="backgroundPhoto">
          <Grid.Row>
            <div style={{ textAlign: "center" }}>
              <h1>
                Welcome to {depot?.tenantName}{" "}
                {depotNameFlag && depot?.code.toUpperCase()}
              </h1>
              <h2>{depot?.address.line1}</h2>
              <h2>{depot?.address.line2}</h2>
              <h2>
                {depot?.address.county}, {depot?.address.eirCode}
              </h2>
            </div>
          </Grid.Row>
        </div>
      </Grid>

      <div id="parent">
        <Button
          id="child"
          className="planned-visit-btn"
          onClick={(e: React.MouseEvent<HTMLButtonElement>, d: any) =>
            openVisitorCodeOptionModal(e, d)
          }
          name="planned"
          // fluid
          // size="massive"
        >
          Sign In <br />
        </Button>

        <Button
          id="child"
          className="unplanned-visit-btn"
          // fluid
          // size="massive"
        >
          <div>
            <h2>
              <Clock date={Date.now()} format={"dddd"} />
            </h2>
            <h1>
              <Clock date={Date.now()} format={"Do"} />{" "}
              <Clock date={Date.now()} format={"MMMM"} />
            </h1>
            <h2>{clockset}</h2>{" "}
          </div>
        </Button>

        <Button
          id="child"
          className="regular-visitor-btn"
          onClick={(e: React.MouseEvent<HTMLButtonElement>, d: any) =>
            openVisitorCodeOptionModal(e, d)
          }
          name="signoout"
        >
          Sign Out <br />
        </Button>
      </div>
    </div>
  );
};
export default observer(VisitorPortal);
