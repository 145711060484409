import axios, { AxiosResponse } from "axios";
import {
  IVisitorModel,
  IVisitorModel1,
  ICheckInAttendee,
  IVisitorCheckingOut,
  IBookedVisitorsReturnLists,
  IQrInfo,
  IRVRep,
  IBookedVisitors,
  IQrSendMail,
  IEditRV
} from "../models/VisitorModel";
import {
  IUserFormValues,
  IUser,
  IRegisterRequestModel,
  IVisitorSearchData,
  IVisitorSearchData1,
  IManualCheckout,
  IEmailNotification,
  IRole
} from "../models/userModel";
import {
  IDateRangeVisitors,
  IPartnerEnvelope,
  IVisitsEnvelope,
  IVisitAttendance,
} from "../models/reportModel";
import { toast } from "react-toastify";
import { IDepot, IDepotDetails, IDepotFormValues, IDepotRequest } from "../models/depot";
import { history } from "..";
import {
  IBookVisitSubmit,
  IPartner,
  IPartnerData,
  IPartnerFile,
} from "../models/bookVisitModel";
import { IResetPassword, ITenantList } from "../models/resetPasswordModel";
import { IVReason } from "../models/visitReason";
import { IBadge, IBadgeData } from "../models/badge";
import {
  IClockOps,
  IClockOpsDetails,
  IPartnerSignature,
  ISignBadgeNum,
} from "../models/printHouseRulesModel";
import { IDepotsDetails, ITenant, ITenantDetailsResult, ITenantRep, ITenantResult } from "../models/tenant";
import { IManager,IManager1 } from "../models/manager";


axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  
  (config) => {
    const token = window.localStorage.getItem("jwt");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  const { status, config } = error.response;
  if (error.message === "Network Error" && !error.response) {
    toast.error("Network error - make sure the API is running");
  }
  if (status === 401) {
 
    history.push("/ml-admin");
    toast.info("Your session has expired, please login again! ");
  }
  if (status === 404) {
    toast.error("Resource Not Found - check the terminal for more info");
  }
  if (status === 400 && config.method === "get" && !config.url.includes('api/get-visitor-before-checkout')) {
    toast.error("Server Error - check the terminal for more info");
  }
  if (status === 500 && !config.url.includes('api/get-visitor-before-checkout')) {
      toast.error("Server Error - check the terminal for more info");
  }



  throw error.response;
});
const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),

  postForm: (url: string, values: ISignBadgeNum) => {
    var formData = new FormData();
    formData.append("sign", values.sign);
    formData.append("depotBadgeNum", values.depotBadge);
    formData.append("depot", values.depot)
    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },

  postPartnerFile: (url: string, file: IPartnerFile) => {
    var formData = new FormData();
    formData.append("file", file.file);
    formData.append("depotId",  file.depotId);
    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },

  postPartnerForm: (url: string, values: IPartnerSignature) => {
    var formData = new FormData();
    formData.append("sign", values.sign!);
    if (values.depotBadge) formData.append("depotBadgeNum", values.depotBadge);
    if (values.workId) formData.append("workId", values.workId);
    formData.append("depot", values.depot);
    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },
};

const partner = {
  //register partner

  listPartners: (tenantId: string, depotId: string): Promise<IPartnerData[]> =>
    request.get(`api/list-partners/${tenantId}/${depotId}`),
  regPartner: (data: IPartner): Promise<boolean> =>
    request.post("api/register-partner", data),
  findPartner: (workId: string): Promise<IPartner> =>
    request.get(`api/get-partner/${workId}`),
  partnerSignature: (values: IPartnerSignature): Promise<AxiosResponse> =>
    request.postPartnerForm("api/submit-partner-signature", values),
  clockIn: (data: IClockOps): Promise<AxiosResponse> =>
    request.post("api/clock-in", data),
  clockOut: (data: IClockOps): Promise<IClockOpsDetails> =>
    request.post("api/clock-out", data),
  fileUpload: (values: IPartnerFile): Promise<AxiosResponse> =>
    request.postPartnerFile("api/partner-bulk-upload", values),
  edit: (partnerId: string, values: IPartnerData): Promise<AxiosResponse> =>
    request.put(`api/edit-partner/${partnerId}`, values),
  delete: (partnerId: string): Promise<AxiosResponse> =>
    request.del(`api/delete-partner/${partnerId}`),
  resendQRcode: (workid: string, data: IQrSendMail): Promise<AxiosResponse> => request.post(`/api/sendqrcode-partners/${workid}`, data)   
};

const visitor = {
  checkIn: (visitor: IVisitorModel): Promise<AxiosResponse> =>  request.post("api/checkIn", visitor),
  checkIn1: (visitor: IVisitorModel1): Promise<AxiosResponse> =>  request.post("api/checkInQR", visitor),
  unplannedVisitCheckIn: (visitor: IVisitorModel): Promise<AxiosResponse> =>  request.post("api/unplanned-visit-checkIn", visitor),
  getStatus: (id: string): Promise<IUser[]> =>
    request.get(`api/get-visitor-status/${id}`),
  getAllVisitor: (): Promise<IUser[]> => request.get("api/get-visitors"),
  bookMeeting: (visitor: IBookVisitSubmit): Promise<boolean> =>
    request.post("api/book-meeting", visitor),
  mobileCheckIn: (meetingId: string): Promise<IUser[]> =>
    request.post("api/mobile-checkIn/", meetingId),
  checkout: (data: IManualCheckout): Promise<AxiosResponse> =>
    request.post("api/checkOut", data),
    QRcheckout: (data: IManualCheckout): Promise<AxiosResponse> =>
    request.post("api/QRcheckOut", data),

  // here i'm going to leave it as is, because the value passed is of the same datatype
  searchByMeetingRefOrBadgeID: (meetingRef: IVisitorSearchData
  ): Promise<ICheckInAttendee> =>
    request.post("api/fetch-visitor-details", meetingRef),
    searchByQRCode: (meetingRef: IVisitorSearchData1
      ): Promise<ICheckInAttendee> =>
        request.post("api/fetch-visitor-QRdetails", meetingRef),
  sendEmail_plannedVisit: (data: IEmailNotification): Promise<AxiosResponse> =>
    request.post("api/planned-visit-email-notification", data),
    sendEmail_regularVisit: (data: IEmailNotification): Promise<AxiosResponse> =>
        request.post("api/regular-visit-email-notification", data),

  sendEmail_unplannedVisit: (data: IEmailNotification): Promise<AxiosResponse> =>
    request.post("api/unplanned-visit-email-notification", data),
  getVisitorName: (depotBadgeNum: string, depotId: string): Promise<IVisitorCheckingOut> =>
    request.get(`api/get-visitor-before-checkout?depotBadgeNum=${depotBadgeNum}&depotId=${depotId}`),
  QRgetVisitorName: (meetingReference: string, depotId: string): Promise<IVisitorCheckingOut> =>
    request.get(`api/get-visitor-before-QRcheckout?meetingReference=${meetingReference}&depotId=${depotId}`),

    QRInfo: (meetingReference: string, ): Promise<IQrInfo> =>
    request.get(`api/QR-Info?meetingReference=${meetingReference}`),

    cancelVisit: (visitRef: string): Promise<AxiosResponse> =>
    request.del(`api/cancel-visit/${visitRef}`),
};

const visitReason = {
  getVReasons: (): Promise<IVReason[]> => request.get("api/reasons"),
};
const regVisitor = {
  getRegVisitorRef: (tenantId: string): Promise<string> =>
    request.get(`api/regular-visitor-reference/${tenantId}`),
};

const report = {
  dailyPartners: (depot: string, offset: number):                 Promise<IPartnerEnvelope> => request.get(`api/daily-partner-report/${depot}/${offset}`),
  populateDailyPartnersList: (depot: string):                     Promise<IPartnerData[]> =>  request.get(`api/daily-partner-report-export/${depot}`),
  dateRangePartners: (values: IDateRangeVisitors):                Promise<IPartnerEnvelope> =>  request.post("api/date-range-partners-report", values),
  populateDateRangePartnersList: (values: IDateRangeVisitors):    Promise<IPartnerData[]> =>request.post(`api/date-range-partners-report-export`, values),

//////////////////////////////////////////////


  dailyVisitors: (depot: string, offset: number):                 Promise<IVisitsEnvelope> => request.get(`api/daily-visit-report?depot=${depot}&offset=${offset}`),
  dateRangeVisitors: (values: IDateRangeVisitors):                Promise<IVisitsEnvelope> => request.post("api/date-range-visitors-report", values),
  populateDailyVisitorsList: (depot: string):                     Promise<IVisitAttendance[]> => request.get(`api/daily-visit-report-export?depot=${depot}`),
  populateDateRangeVisitorsList: (values: IDateRangeVisitors ):   Promise<IVisitAttendance[]> => request.post("api/date-range-visitors-report-export", values),
  //dailyPlannedVisitor: (depot: string):                         Promise<IVisitAttendance[]> =>request.get(`api/todays-planned-visits/${depot}`),
  dailyPlannedVisitor: (depot: string, offset: number):           Promise<IVisitsEnvelope> =>request.get(`api/todays-planned-visits?depot=${depot}&offset=${offset}`),
  viewVisitorQuestionnaire: (visitorId: string,depot: string):    Promise<AxiosResponse> => request.get(`api/view-visitor-questionnaire/${depot}/${visitorId}`),
};

const roles = {
  list: (): Promise<IRole[]> => request.get("/api/get-roles"),
};



const User = {
  Current: (): Promise<IUser> => request.get("api/user"),
  login: (user: IUserFormValues): Promise<IUser> =>request.post("api/login", user),
  register: (values: IRegisterRequestModel): Promise<AxiosResponse> => request.post("api/register-user", values),
  resetPassword: (values: IResetPassword) =>request.post("api/user-reset-password", values),
  forgetPassword: (username: string): Promise<AxiosResponse> => request.get(`/request-password-email/${username}`),
};

const submitSign = {
  formSignature: (values: ISignBadgeNum): Promise<AxiosResponse> =>request.postForm("api/submit-signature", values),
};

const depot = {
  list: (tenantId: string ): Promise<IDepot[]> => request.get(`api/depots/${tenantId}`),
  edit: (id: string,values: IDepotsDetails ):Promise<AxiosResponse> => request.put(`api/update-depot-details/${id}`,values),
  create: (values: IDepotFormValues): Promise<AxiosResponse> =>  request.post("api/add-depot", values),
  login: (values: IDepotDetails): Promise<IDepotRequest> => request.post("api/depot-login", values),
  find: ( tenantName: string, depotCode: string): Promise<IDepotRequest> =>request.get(`api/find-depot/${tenantName}/${depotCode}`),
};

const badge = {
  create: (values: IBadge):                 Promise<AxiosResponse> => request.post("api/add-badge-number", values),
  getBadge: (depotId: string):              Promise<Number> => request.get(`api/get-badge-number/${depotId}`),
  find:(badgeNum: string, depotId: string): Promise<IBadgeData> => request.get(`api/get-badge-assignee-details/${badgeNum}/${depotId}`)

};


const tenant = {
  create: (formValues: ITenant): Promise<AxiosResponse> => request.post('api/create-tenant', formValues),
  list: (): Promise<ITenantResult[]> => request.get("/api/list-tenants"),
  edit: (tenantID: string, formValues: ITenant): Promise<AxiosResponse> => request.put(`/api/edit-tenant-details/${tenantID}`, formValues),
  remove:(tenantID: string): Promise<AxiosResponse> => request.del(`/api/remove-tenant/${tenantID}`),
  find:(tenantId: string): Promise<ITenantDetailsResult> => request.get(`/api/find-tenant/${tenantId}`),
  shortList: (): Promise<ITenantList[]> => request.get("/api/tenants-short-list"),
}

const manager = {
  create: (formValues: IManager): Promise<AxiosResponse> => request.post('api/add-manager-data', formValues),
 // list: (): Promise<IManager[]> => request.get("/api/list-tenants"),
 list: (depotId: string ): Promise<IManager1[]> => request.get(`api/list-managers/${depotId}`),
 list1: (depotId: string ): Promise<IManager1[]> => request.get(`api/list-emailmanagers/${depotId}`),
 edit: (managerId: string, formValues: IManager): Promise<AxiosResponse> => request.put(`api/edit-manager-details/${managerId}`, formValues),
 remove: (managerId: string): Promise<AxiosResponse> => request.del(`api/delete-manager-details/${managerId}`)
}

const bookedVisitor = {
  list: (tenantID: string, depotId: string): Promise<IBookedVisitorsReturnLists> => request.get(`/api/list-booked-visitor/${tenantID}/${depotId}`),
}

const representative = {
  edit: (repId: string, data: ITenantRep): Promise<AxiosResponse> => request.post(`/api/edit-representative/${repId}`, data),
  remove:(repId: string): Promise<AxiosResponse> => request.del(`/api/remove-rep/${repId}`),
  resendEmail: (repId: string): Promise<AxiosResponse> => request.post(`/api/resend-confirmation-email/${repId}`,{})
                                                                  
 
}

const regularvisitor = {
  edit: (MeetingRef: string, data: IEditRV): Promise<AxiosResponse> => request.put(`/api/edit-RV/${MeetingRef}`, data),
  remove:(AttendeeId: string): Promise<AxiosResponse> => request.del(`/api/remove-RV/${AttendeeId}`),
  resendQREmail: (meetingReference: string, data: IQrSendMail): Promise<AxiosResponse> => request.post(`/api/resend-confirmation-email-RV/${meetingReference}`, data),  
  edithost :(hostid: string, data: IEditRV): Promise<AxiosResponse> => request.put(`/api/edit-Host/${hostid}`, data)                                                          
 
}

const regularVisitorlist = {
  rvlist: (tenantID: string, depotId: string): Promise<IBookedVisitorsReturnLists> => request.get(`/api/list-regular-visitor/${tenantID}/${depotId}`),
}


export default {
  bookedVisitor,
  tenant,
  User,
  visitor,
  partner,
  depot,
  representative,
  report,
  visitReason,
  regVisitor,
  badge,
  submitSign,
  roles,
  manager,
  regularVisitorlist,
  regularvisitor

};
