import { observable, action } from "mobx";
import { toast } from "react-toastify";
import agents from "../API/agents";
import { IManager } from "../models/manager";
import { history } from "..";

import { RootStore } from "./rootStore";

export default class ManagerStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable updateData: boolean = false;
  @observable emailReceivedFlagChanged: boolean = false;
  @observable receiveEmailFlag: boolean = true;

  @action handleReceiveEmailFlag = async () => {
    this.receiveEmailFlag = !this.receiveEmailFlag;
  };

  @action handleEmailFlagIfUpdating = async (data: IManager) => {
    this.receiveEmailFlag = data.receiveEmailFlag;

  };
  @action handleUpdateClick = async () => {
    this.updateData = !this.updateData;
  };

  @action handleEmailRecipientDataClick = async () => {
    this.receiveEmailFlag = !this.receiveEmailFlag;
  };

  @action addManager = async (data: IManager) => {
    try {
      data.receiveEmailFlag = this.receiveEmailFlag;

      const result = await agents.manager.create(data);
      if (result) {
        this.rootStore.modalStore.closeModal();
        await this.rootStore.tenantStore.findTenant({
          tenantId: data.tenantId!,
        });
        this.rootStore.modalStore.closeModal();
      }
    } catch (error) {
      throw error;
    }
  };

  @action updateManager = async (data: IManager) => {
    try {
      data.receiveEmailFlag = this.receiveEmailFlag;
      const result = await agents.manager.edit(data.id, data);
      if (result) {
        await this.rootStore.tenantStore.findTenant({
          tenantId: data.tenantId!,
        });
        this.rootStore.modalStore.closeModal();
        this.updateData = false;
        toast.success("Manager successfully updated!");
      }
    } catch (error) {
      throw error;
    }
  };
  @action DeleteManager = async (ManagerId: string) => {
    try {
      const result = await agents.manager.remove(ManagerId)


      if(result)
      console.log(result, "deleted information");
      this.rootStore.modalStore.closeRvMModal();
      this.rootStore.modalStore.closeModal();

      history.push("/tenants");
      

      toast.success("Manager deleted successfully");
    } catch (error) {
      throw error;
    }
  };

  @action removeManager = async () => {
    try {
    } catch (error) {
      throw error;
    }
  };
}
