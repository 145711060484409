import { th } from "date-fns/locale";
import { action, observable } from "mobx";
import { toast } from "react-toastify";
import agents from "../API/agents";
import {  IBookedVisitors, IQrSendMail,IEditRV } from "../models/VisitorModel";
import ReportStore from "./reportStore";
import { RootStore} from "./rootStore";
import VisitorStore from "./visitorStore";



export default class RefStore {
  rootStore: RootStore;
  visitorstore : VisitorStore
  reportstore :ReportStore
  
  
  @observable updateDataRV: boolean = false;
  @observable HostdetailsUpdate: boolean = false;

  @observable removingRepLoadingRV: boolean = false;
  @observable EnterTemperature: boolean = false;
  @observable SignFlag: boolean = false;
  @observable badgeNumFlag: boolean = false;


  constructor(rootStore: RootStore,  visitorstore : VisitorStore, reportstore :ReportStore ) {
    this.rootStore = rootStore;
    this.visitorstore = visitorstore;
    this.reportstore = reportstore;
    const { user } = rootStore.userStore;
 
  }
  
  @observable depotSelected: string = "";

  @action setDepotSelected = (depot: string) => {
    this.depotSelected = depot;
  };

  @action setFlags = async (signFlag: boolean, badgeNumFlag: boolean, EnterTemperature: boolean) => {
    this.SignFlag = signFlag;
    this.badgeNumFlag = badgeNumFlag;
    this.EnterTemperature= EnterTemperature;
  };

  


  
  @action handleUpdateSignFlag = async () => {
    this.SignFlag = !this.SignFlag;
  };
  @action handleUpdateEnterTemperature= async () => {
    this.EnterTemperature = !this.EnterTemperature;
  };
  @action handleUpdateBadgeNumFlag= async () => {
    this.badgeNumFlag = !this.badgeNumFlag;
  };


  @action ResendConfirmationEmail = async (meetingref: string) => {

    let data: IQrSendMail ={
      meetingReference : meetingref
    }
    try{
      const result = await agents.regularvisitor.resendQREmail(meetingref, data);
      if(result)
      {
        this.rootStore.modalStore.closeModal();
        toast.info("New confirmation email sent!");
      }
    
    }  catch (error) {
      throw error;
    }
  }
// EDIT REGULAR VISITOR INFORMATION///////////////
  @action Edit = async (data: IEditRV) => {
    const { user } = this.rootStore.userStore;
    data.badgeNumFlag = this.badgeNumFlag;
    data.signFlag  = this.SignFlag;
    data.enterTemperature = this.EnterTemperature;
    this.updateDataRV =false;
    try{
    const result = await agents.regularvisitor.edit(data.meetingReference, data);

    console.log("Data", result);
      if (result) {

       await this.rootStore.reportStore.ListBookedVisitors(
        this.rootStore.userStore.user?.tenantId!,
        this.rootStore.userStore.user?.depot.id!
      );
       
        this.rootStore.modalStore.closeModal();
        toast.success("Regular Visitor successfully updated!");

      }
    
    }  
    catch (error) 
    {
      throw error;
    
    }
  }


  @action EditHostinfo = async (data: IEditRV) => 
  {
    this.updateDataRV =false;

    try{
      const result = await agents.regularvisitor.edithost(data.hostid,data);
      console.log("Data", result);
        if (result) {
  
         await this.rootStore.reportStore.ListBookedVisitors(
          this.rootStore.userStore.user?.tenantId!,
          this.rootStore.userStore.user?.depot.id!
        );
         
          this.rootStore.modalStore.closeModal();
          toast.success("Host Details successfully updated!");
  
        }
      
      }  
      catch (error) 
      {
        throw error;
      
      }

  }






  @action handleUpdateClick = async () => {
    this.updateDataRV = true;
  };

  @action handleHostUpdateClick = async () => {
    this.HostdetailsUpdate = true;
  };

  @action RemoveRv = async (AttendeeId: string, tenantId: string) =>
   {


    var tenant_name = this.rootStore.tenantStore.tenant?.id!;
    var depot_code = this.rootStore.depotStore.depot?.id!;
        this.updateDataRV = false;
        this.removingRepLoadingRV = true

        try{
          this.rootStore.modalStore.closeRvModal()
          const result = await agents.regularvisitor.remove(AttendeeId);

          if(result)
          {
            await this.rootStore.tenantStore.findTenant({tenantId: tenantId}).then(()=>this.removingRepLoadingRV =false);
            this.rootStore.modalStore.closeRvModal();
          }
        }
          catch (error) 
          {
            throw error;
          }
        
  };

 

 
  @action resetUpdateRV = async () => (this.updateDataRV = false);

  @action resetUpdateHost = async () => (this.HostdetailsUpdate = false);



}


