import React from "react";
import { Modal, Icon, Button,Header, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import QrReader from 'react-qr-reader';
import { RootStore, RootStoreContext } from "../../stores/rootStore";
import { useContext } from "react";



interface IProps {
    open: boolean;
  
    hasSignOutValidation: (name: string) => void;
}
const SignoutValidationModal: React.FC<IProps> = ({ open, hasSignOutValidation }) => {


    const rootstore = useContext(RootStoreContext);
    const{SignOutValidationModal} = rootstore.visitorStore
    return (
        <Modal  closeIcon={{ style: { top: '1.0535rem', right: '1rem' }, color: 'black', name: 'close' }} className="tempvalidation-option-modal" open={open} size="small" 
        dimmer="blurring" closeOnDimmerClick
        onClose= {SignOutValidationModal}
        >
    
          <Modal.Header className = "validation-modal">
          <b>SIGN OUT Warning !!!</b>
          </Modal.Header>

          <Modal.Content className = "signout-content">
          <Grid>
            
              <h3>
                
              You have Already Signed out. Do you want to Sign In?
                  
                
              </h3>
              {/* <Image centered src={tempInstructions} /> */}
          
          </Grid>
        </Modal.Content>
          <Modal.Actions  className = "button-action">
            
            <Button  positive onClick={() => hasSignOutValidation("yes")}>   
              Yes
            </Button>
            <Button   color="red" onClick={() => hasSignOutValidation("no")}>
              No
            </Button>
          </Modal.Actions>
        </Modal>
        
    );
};

export default observer(SignoutValidationModal) ;