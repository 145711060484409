import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Table,
  Image,
  Message,
} from "semantic-ui-react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import logo from "../../assets/visIT_logo.png";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import HouseRules from "../Modals/HouseRules";
import SecurityRules from "../Modals/SecurityModal";
import HealthSafety from "../Modals/HealthSafety";
import OtherRules from "../Modals/OtherRules";
import ErrorMessage from "../layout/ErrorMessage";
import { history } from "../..";
import "../../style/visitor.css";
import TemperatureInstruction from "../Modals/TemperatureInstruction";
import TemperatureValidationMessage from "../Modals/TemperatureValidationModal";
import QrReader from 'react-qr-reader';
import agents from '../../API/agents';
import LoadingComponent from "../Modals/LoadingComponents";


const PlannedVisit = (props) => {
  const [inputName, setInputName] = useState("");
  const [layout, setLayout] = useState("default");
  const [scanwebcam1, setScanWebCam] = useState('');
  
  const rootStore = useContext(RootStoreContext);
  const {
    SearchVisitByBadgeNum,
    SearchVisitByQrCode,
    virtualKeyboardVisible,
    meetingRefOrBadgeNum,
    scanwebcam,
    onChangeSwitch,
    closeTemWindow,
    firstName,proceedBtnPlannedVisitor,
    errors,
    axiosResponse,
    lastName,
    email,
    company,
    meetingWith,
    carReg,
    temInstructions,
    OpenConditionsWindow,
    TempvalidationPlanned,
    healthSafetyWindow,
    temperature,
    otherRulesWindow,
    securityRulesWindow,
    highlightInputBorderCarReg,
    highlightInputBorderTemp,
    houseRulesWindow,
    temInstructionsFlag1,
    AcceptConditions,
    AcceptConditionsPlanned,
    clearVisitorData,
    openTempInstructionWin,
    DennyConditions,
    hideVirtualKeyboardVisible,
    validateForm,
    TemperatureValidationModal,
      TemperatureValidation,
      loading
  } = rootStore.visitorStore;
  const { depot, getDepot } = rootStore.depotStore;
 
  const keyboard = useRef();
  const setActiveInput = (event) => setInputName(event.target.id);

  const onChange = (input) => {
    onChangeSwitch(inputName, input);
  };


  const { match } = props;
  let { tenantName, depotCode } = match.params;

  useEffect(() => {

    if (!depot) {
      getDepot(tenantName, depotCode);
    }

    document.getElementById("meetingRefOrBadgeNum").focus();
    if (!temInstructions && !temInstructionsFlag1)
      document.getElementById("temperature").focus();

    
    if (firstName.length > 0 && temInstructionsFlag1) document.getElementById("carReg").focus();

    if (errors) 
      keyboard.current.clearInput("meetingRefOrBadgeNum");
      if (!depot) {
        getDepot(tenantName, depotCode);
      }
    hideVirtualKeyboardVisible(window.innerWidth);
    validateForm();
  }, [depot,depotCode, tenantName, getDepot, hideVirtualKeyboardVisible,errors, validateForm, temInstructionsFlag1, temInstructions, firstName]);

  const onChangeForm = (event) => {
    onChangeSwitch(event.target.name, event.target.value);
    keyboard.current.setInput(event.target.value);
  };

  const handleShift = () =>
    setLayout(layout === "default" ? "shift" : "default");

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    TempvalidationPlanned();
    //OpenConditionsWindow("planned-visitor");
  };
  const BackHomeClick = () => {
    clearVisitorData();
    history.push(`/${tenantName}/${depotCode}`);
  };

  const qrcodecallback = (resultone) =>
  {

   const searchvisit =  SearchVisitByQrCode(tenantName, depotCode, resultone);
  
  };

  const handleScanWebcam = (result) => {
    setScanWebCam(result);   
   if(result){
    qrcodecallback(result);
   }    
  };
  if(loading) return <LoadingComponent content ="Please Wait"/>
  return (
    <Fragment>
      {/* // show temperature instruction */}
      {/* <TemperatureInstruction
        open={temInstructions && temInstructionsFlag1}
        accept={closeTemWindow}
      /> */}
      {/* // end instructions */}

      <HouseRules
        open={houseRulesWindow}
        denny={DennyConditions}
        accept={AcceptConditionsPlanned}
      />
      <SecurityRules
        open={securityRulesWindow}
        denny={DennyConditions}
        accept={AcceptConditionsPlanned}
      />

      <TemperatureValidationMessage
      open= {TemperatureValidationModal}
      accept={TemperatureValidation}
      />

      <HealthSafety
        open={healthSafetyWindow}
        denny={DennyConditions}
        accept={AcceptConditionsPlanned}
      />
      <OtherRules
        open={otherRulesWindow}
        denny={DennyConditions}
        accept={AcceptConditionsPlanned}
      />

      <Button
        style={{ marginBottom: 15, marginLeft: 25, marginTop: 20 }}
        size="big"
        icon="home"
        onClick={BackHomeClick}
        default
        content="Home"
      />
      <Grid centered style={{ marginTop: 5 }} className="formInput">
        <Image
          src={logo}
          style={{
            marginTop: 15,
          }}
        />
        

        <Grid.Row width="equal" centered columns={3}>
          {/* <Grid.Column width={4}>
            <h3>Please Enter Your Meeting Reference</h3>
          </Grid.Column> */}

          {/* <Grid.Column floated= {"left"} width={3} >
           <QrReader
                  delay={100}
                  style={{ width: '50%' }}
                  onScan={handleScanWebcam}
                  />
                  

            </Grid.Column> */}
          
          <Grid.Column width={3}>
            <Form>
              <Form.Field>
                <input
                  autoComplete={"off"}
                  id="meetingRefOrBadgeNum"
                  name="meetingRefOrBadgeNum"
                  type="text"
                  value={meetingRefOrBadgeNum} 
                  placeholder="Meeting Reference"
                  onFocus={setActiveInput}
                  onChange={onChangeForm}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          
            
          { <Grid.Column width={2}>
          
            <Button
              //disabled={}
              content="Find Me"
              color="facebook"
              fluid
              onClick={() => SearchVisitByBadgeNum(tenantName, depotCode)}
            />
          </Grid.Column>}


        </Grid.Row>

        <Grid.Column width={11}>
          <Segment style={{ border: 0, marginTop: 10 }}>
            <Message warning style={{ fontSize: 16 }}>
              {" "}
              Please <strong>Note</strong>: All fields bellow must be completed!
            </Message>
            <Form onSubmit={handleSubmit} error method="post">
              <Table size="large" style={{ width: "100%", border: 0 }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Field>
                        <input
                          readOnly={true}
                          autoComplete={"off"}
                          id="fName"
                          name="fName"
                          type="text"
                          onChange={onChangeForm}
                          value={firstName}
                          placeholder="First Name"
                          onFocus={setActiveInput}
                          required
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Field>
                        <input
                          readOnly={true}
                          autoComplete={"off"}
                          id="lName"
                          name="lName"
                          type="text"
                          value={lastName}
                          onChange={onChangeForm}
                          placeholder="Last Name"
                          onFocus={setActiveInput}
                          required
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Field>
                        <input
                          readOnly={true}
                          autoComplete={"off"}
                          id="email"
                          name="email"
                          type="email"
                          value={email}
                          onChange={onChangeForm}
                          placeholder="Email"
                          onFocus={setActiveInput}
                          required
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Field>
                        <input
                          readOnly={true}
                          autoComplete={"off"}
                          id="company"
                          name="company"
                          type="text"
                          onChange={onChangeForm}
                          value={company}
                          placeholder="Company"
                          onFocus={setActiveInput}
                          required
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Group>
                        <Form.Field width="16">
                          <input
                            autoComplete={"off"}
                            id="carReg"
                            name="carReg"
                            type="text"
                            required
                            onChange={onChangeForm}
                            value={carReg}
                            className={
                              highlightInputBorderCarReg
                                ? " highlightInputBorder"
                                : ""
                            }
                            placeholder="Car Registration"
                            onFocus={setActiveInput}
                          />
                        </Form.Field>
                        {/* <Form.Field width="6">
                          {temInstructionsFlag1 ? (
                            <input
                              autoComplete={"off"}
                              id="temp"
                              name="temperature"
                              type="text"
                              maxLength = {4}
                              //required
                              disabled={true}
                              onClick={openTempInstructionWin}
                              className={
                                highlightInputBorderTemp
                                  ? " highlightInputBorder"
                                  : ""
                              }
                              onChange={onChangeForm}
                              value={temperature}
                              placeholder="Temperature"
                              onFocus={setActiveInput}
                            />
                          ) : (
                            <input
                              autoComplete={"off"}
                              id="temperature"
                              name="temperature"
                              type="text"
                              maxLength = {4}
                              //required
                              disabled={true}
                              className={
                                highlightInputBorderTemp
                                  ? " highlightInputBorder"
                                  : ""
                              }
                              onChange={onChangeForm}
                              value={temperature}
                              placeholder="Temperature"
                              onFocus={setActiveInput}
                            />
                          )}
                        </Form.Field> */}
                      </Form.Group>
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Field>
                        <input
                          readOnly={true}
                          autoComplete={"off"}
                          id="meetingWith"
                          name="meetingWith"
                          type="text"
                          onChange={onChangeForm}
                          value={meetingWith}
                          placeholder="Meeting With"
                          onFocus={setActiveInput}
                          required
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Button
                size="big"
                fluid
                style={{
                  backgroundColor: "#4db25e",
                  color: "#FFF",
                  fontSize: 15,
                  borderRadius: 5,
                }}
                disabled={proceedBtnPlannedVisitor && !carReg && !temperature}
              >
                Proceed
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Row centered>
          <Grid.Column width={11}>
            {errors && <ErrorMessage error={axiosResponse} text={errors} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {virtualKeyboardVisible && (
        <div className="keyboardPosition">
          <Keyboard
            inputName={inputName}
            useMouseEvents={true}
            keyboardRef={(r) => (keyboard.current = r)}
            layoutName={layout}
            //layout="numeric"
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </div>
      )}
      {!virtualKeyboardVisible && (
        <div>
          {" "}
          <br />
          <br />
          <br />
        </div>
      )}
    </Fragment>
  );
};
export default observer(PlannedVisit);
