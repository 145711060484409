import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../../stores/rootStore";
import { Field, Form as FinalForm } from "react-final-form";
import { observer } from "mobx-react-lite";
import {
  Grid,
  Header,
  Form,
  Button,
  Pagination,
} from "semantic-ui-react";
import { FORM_ERROR } from "final-form";
import SelectInput from "../../../common/form/SelectInput";
import {
  IDailyVisits,
  IDateRangeVisitors,
  IVisitAttendance,
} from "../../../models/reportModel";
import VisitorCard from "./Cards/VisitorCard";
import PlannedVisitorCard from "./Cards/PlannedVisitorCard";
import DateInput from "../../../common/form/DateInput";
import { ExportCSV } from "../../../common/util/utils";
import { PaginationProps, Toast } from "reactstrap";
import ReportLayout from "../../layout/ReportLayout";
import { combineValidators, isRequired } from "revalidate";
import { toast } from "react-toastify";

const validate = combineValidators({
  depot: isRequired("Depot"),
  dateFrom: isRequired("dateFrom"),
  dateTo: isRequired("dateTo")
  


});

const VisitorReports: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
  //DailyVisitorsReport,
    DateRangeVisitorsReport,
    visitors,
    VisitorReportDataDaily,
    VisitorReportDataDateRange,
    changePage,
    changePageDateRange,
    visitorsDateRangeEnvelope,
    totalPagesDaily,
    totalPagesDateRange,
    getDailyPlannedVisitsReport,
    setPageNumber_daily,
    setPageNumber_DateRange,
    ResetDateRangedEnvelope,
    tempDepot,
    visitorsDailyPlannedVisitsEnvelope,
    DailyPlannedVisitsArr,
  } = rootStore.reportStore;
  const {
    // ListDepot,
    depots,
    depotId,
    // tenantId,
    ListByUserDepot,
  } = rootStore.depotStore;
  // const { listTenants_shortlist, shortListTenant } = rootStore.tenantStore;

  const [dateRangeData, setDateRangeData] = useState<IDateRangeVisitors>();
  const [loading, setLoading] = useState(true);
  const handleGetNext = (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => {
    setPageNumber_daily(data.activePage);
    changePage();
  };

  const handleGetNextDateRange = (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => {
    setPageNumber_DateRange(data.activePage);
    changePageDateRange(dateRangeData!);
  };

  useEffect(() => {
    ListByUserDepot().then(() => setLoading(false));
    return () => {
      ResetDateRangedEnvelope();
    };

   
 
  }, [setLoading,ListByUserDepot, ResetDateRangedEnvelope]);

  return (
    <ReportLayout>
      {/* for super admin only */}

      <Grid centered>
        <Grid.Row columns={2} widths={"equal"}>
          <Grid.Column>
            <Header style={{ borderWidth: 0 }} as="h3" attached="top">
              Todays Planned Visits
            </Header>

            <FinalForm
              onSubmit={(values: IDailyVisits) => {
                getDailyPlannedVisitsReport(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }));
              }}
              //validate={validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error>
                  <Field
                    label="Depot"
                    name="depot"
                    placeholder="Depot"
                    options={depots}
                    loading={loading}
                    component={SelectInput}
                  />
                  <br />
                  <br />

                  <div className="searchBtn">
                    <Button
                      fluid
                      icon="search"
                      loading={submitting}
                      disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                      primary
                      content="Search"
                    />
                    <br />

                  </div>
                </Form>
              )}
            />
            <div className="report-data-container">
              {visitorsDailyPlannedVisitsEnvelope
                ? visitorsDailyPlannedVisitsEnvelope.visit.map(
                    (e: IVisitAttendance, i: number) => {
                      return (
                        <PlannedVisitorCard key={i} attendee={e} depotId={depotId} />
                      );
                    }
                  )
                : ""}
            </div>
          </Grid.Column>
                 <Grid.Column>
            <Header style={{ borderWidth: 0 }} as="h3" attached="top">
              Visitors by Date Range
            </Header>

            <FinalForm
              onSubmit={(values: IDateRangeVisitors) => {
                setDateRangeData(values);
                DateRangeVisitorsReport(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }));
              }}
                 validate={validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error>
                  <Form.Group widths="equal">
                    <Field
                      label="Depot"
                      name="depot"
                      placeholder="Depot"
                      options={depots}
                      loading={loading}
                      component={SelectInput}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Field
                      name="dateFrom"
                      date={true}
                      placeholder="From"
                      component={DateInput}
                    />

                    <Field
                      name="dateTo"
                      placeholder="To"
                      date={true}
                      component={DateInput}
                    />
                  </Form.Group>
                  <Button
                    fluid
                    icon="search"
                    loading={submitting}
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine }
                    primary
                    content="Search"
                  />
                  <br />

                  <Button
                    content="Export"
                    fluid
                    disabled={
                      visitorsDateRangeEnvelope?.visits?.length === 0 ||
                      visitorsDateRangeEnvelope === null
                    }
                    icon="file excel outline"
                    onClick={() =>
                      ExportCSV(
                        VisitorReportDataDateRange,
                        "DateRangeVisitorsReport"
                      )
                    }
                  />
                </Form>
              )}
            />
            <div className="report-data-container">
              {visitorsDateRangeEnvelope
                ? visitorsDateRangeEnvelope.visits.map(
                    (e: IVisitAttendance, i: number) => {
                      return (
                        <VisitorCard key={i} attendee={e} depotId={depotId} />
                      );
                    }
                  )
                : ""}
            </div>
            {visitorsDateRangeEnvelope && (
              <Pagination
                firstItem={null}
                lastItem={null}
                onPageChange={handleGetNextDateRange}
                totalPages={totalPagesDateRange}
                ellipsisItem={null}
                defaultActivePage={1}
                pointing
                secondary
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ReportLayout>
  );
};

export default observer(VisitorReports);
