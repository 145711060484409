import React, { useContext, useEffect, useState } from "react";
import { Grid, Header, Button, Segment, Form, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/rootStore";
import ReportLayout from "../../layout/ReportLayout";
import { Field, Form as FinalForm } from "react-final-form";
import { observer } from "mobx-react-lite";
import SelectInput from "../../../common/form/SelectInput";
import ViewRV from "../../admin/ManageVisitors/ViewRV";
import EditHost from "../../admin/ManageVisitors/EditHost";

const BookedVisitors = () => {
  const rootStore = useContext(RootStoreContext);
  const { depots, ListByUserDepot } = rootStore.depotStore;
  const { bookedVisitorsReturnList, ResetListBookedVisitors } =
    rootStore.reportStore;
  const [loading, setLoading] = useState(true);

  const { openModal } = rootStore.modalStore;
  useEffect(() => {
    ListByUserDepot().then(() => setLoading(false));
    return () => {
      ResetListBookedVisitors();
    };
  }, [setLoading, ListByUserDepot, ResetListBookedVisitors]);
  return (
    <ReportLayout>
      <Segment>
        <FinalForm
          onSubmit={() => {}}
          render={() => (
            <Form error>
              <Field
                label="Depot"
                name="depot"
                placeholder="Depot"
                loading={loading}
                componentFlag={"viewBookedVisits"}
                component={SelectInput}
                options={depots}
              />
            </Form>
          )}
        />
      </Segment>
      <Grid columns="2">
        <Grid.Column width="8" className="booked-visits-grid-column">
          <Header as="h2" content="Planned Visitors" />
          {bookedVisitorsReturnList?.plannedVisitors && (
            <div className="booked-visits-tbl">
              <Table color="green">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Ref</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Company</Table.HeaderCell>
                    <Table.HeaderCell>Date of Visit</Table.HeaderCell>
                    <Table.HeaderCell>Email Sent?</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {bookedVisitorsReturnList?.plannedVisitors &&
                    bookedVisitorsReturnList.plannedVisitors.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{e.meetingReference}</Table.Cell>
                        <Table.Cell>{e.name}</Table.Cell>
                        <Table.Cell>{e.email}</Table.Cell>
                        <Table.Cell>{e.company}</Table.Cell>
                        <Table.Cell>
                          {e.dateOfVisit?.toString().split("T")[0]}
                        </Table.Cell>
                        <Table.Cell>{e.emailFlag?.toString()}</Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          )}
        </Grid.Column>
        <Grid.Column width="8" className="booked-visits-grid-column">
          <Header as="h2" content="Regular Visitors" />

          {bookedVisitorsReturnList?.regularVisitors && (
            <div className="booked-visits-tbl">
              <Table className="booked-visits-tbl" color="blue">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>RV Number</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Company</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {bookedVisitorsReturnList?.regularVisitors &&
                    bookedVisitorsReturnList?.regularVisitors.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{e.meetingReference}</Table.Cell>
                        <Table.Cell>{e.name}</Table.Cell>
                        <Table.Cell>{e.email} </Table.Cell>
                        <Table.Cell>{e.company} </Table.Cell>
                        <Table.Cell> </Table.Cell>

                        <Table.Cell>
                          <Button
                            compact
                            content="View"
                            icon="eye"
                            color="orange"
                            labelPosition="left"
                            onClick={() =>
                              openModal(<ViewRV Aid={e} isViewDetails={true} />)
                            }
                          />
                        </Table.Cell>

                        <Table.Cell>
                          <Button
                            compact
                            content="Edit Host"
                            icon="eye"
                            color="orange"
                            labelPosition="left"
                            onClick={() =>
                              openModal(
                                <EditHost Hid={e} isViewDetails={true} />
                              )
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          )}
        </Grid.Column>
      </Grid>
    </ReportLayout>
  );
};

export default observer(BookedVisitors);
