import { observable, computed, action } from "mobx";
import agent from "../API/agents";
import {
  IUser,
  IUserFormValues,
  IRegisterModel,
  IRegisterRequestModel,
  IRole,
} from "../models/userModel";
import { IResetPassword } from "../models/resetPasswordModel";
import { RootStore } from "./rootStore";
import { history } from "..";
import { toast } from "react-toastify";
import { resetObject } from "../common/util/utils";

export default class UserStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable userRoles: IRole[] = [];
  @observable user: IUser | null = null;


@observable RequestPassword = async  (user: IUser) =>{
  try {
    const response = await agent.User.forgetPassword(user.username);
    if (response) {
      history.push("/");
      toast.info("Please check your email!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
  }
}
  @computed get isLoggedIn() {
    return !!this.user;
  }
  @action Login = async (values: IUserFormValues) => {
    try {
      const user = await agent.User.login(values); 
      if (user) {
        this.user = user;
        this.rootStore.depotStore.ListByUserDepot()
        this.rootStore.commonStore.setToken(user.token);
        history.push("/dashboard");
      } 
    } catch (error) {
      console.error(error);
      
      throw error;
    }
  };
  @action loadUserRole = async () => {
    try {
      const data = await agent.roles.list();
      if (data) this.userRoles = data;
    } catch (error) {
      throw error;
    }
  };
  @action Register = async (values: IRegisterModel) => {
    try {
      const depotResult = this.rootStore.depotStore.depots.find(
        (d) => d.value === values.depotString
      );
      const requestValues: IRegisterRequestModel = {
        name: values.name,
        tenantId: values.tenantId,
        depotId: depotResult?.id!,
        username: values.username,
        email: values.email,
        password: values.password,
        department: values.department,
        role: values.role,
      };
      const result = await agent.User.register(requestValues);
      if (result) {
        resetObject(values);
        toast.success('Rep. was successfully registered!')
        this.rootStore.modalStore.closeModal()
      }
    } catch (error) {
      throw error;
    }
  };
  @action getUser = async () => {
    try {
      const user = await agent.User.Current();

      if (user){
        this.rootStore.depotStore.ListByUserDepot()
         this.user = user;
      }
    } catch (error) {
      this.user = null;
      this.logout()
      history.push("/ml-admin");
     
    }
  };
  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
 
    this.user = null;
    history.push("/");
  };
  @action ResetPassword = async (values: IResetPassword) => {
    try {
      const result = await agent.User.resetPassword(values);
      if (result) {
        toast.info("Please login now");
        history.push("/ml-admin");
      }
    } catch (error) {
      throw error;
    }
  };
}