import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Modal } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/rootStore";

const RemoveRegularVisitor: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { RvModal, AttendeeId, tenantId,closeRvModal } = rootStore.modalStore;
  const { RemoveRv } = rootStore.rvStore;

  return (
    <Modal
      size="small"
      open={RvModal}
      onClose={closeRvModal}
      style={{
        height: 124,
        left: "auto",
        right: "auto",
        marginTop: 90,
      }}
    >
      <Modal.Header as="h2" content="Remove Regular Visitor?" />
      <Modal.Actions>
        <Button
          color="green"
          content="Yes"
          onClick={() => RemoveRv(AttendeeId,tenantId)}
        />
        <Button color="red" content="No" onClick={closeRvModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default observer(RemoveRegularVisitor);
