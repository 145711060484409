import React from "react";
import { Modal, Icon, Button,Header, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import QrReader from 'react-qr-reader';
import { RootStore, RootStoreContext } from "../../stores/rootStore";
import { useContext } from "react";



interface IProps {
    open: boolean;
  
    accept: () => void;
}
const TemperatureValidationModal: React.FC<IProps> = ({ open, accept }) => {


    //const rootstore = useContext(RootStoreContext);
    //const{SignOutValidationModal} = rootstore.visitorStore

    return (
        <Modal  closeIcon={{ style: { top: '1.0535rem', right: '1rem' }, color: 'black', name: 'close' }} className="temp-modal" open={open} size="small" 
        dimmer="blurring" closeOnDimmerClick
        onClose= {accept}
        >
    
          <Modal.Header className = "validation-modal">
          <b>Temperature Warning</b>
          </Modal.Header>

          <Modal.Content  className="temperature-modal">
          <Grid>
            
              <h2>
                
                  Your Temperature is outside acceptable limits to enter the Building. Please wait at the reception
                  and contact your Host.
                  
                  
                
              </h2>
              {/* <Image centered src={tempInstructions} /> */}
          
          </Grid>
        </Modal.Content>
          <Modal.Actions className = "Temp-modal">
            
          <Button positive onClick={accept}>
            Continue
          </Button>
          </Modal.Actions>
        </Modal>
        
    );
};

export default observer(TemperatureValidationModal) ;