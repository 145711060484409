import React, { useContext, useEffect } from "react";
import { Segment } from "semantic-ui-react";
import {  IPartnerData } from "../../../models/bookVisitModel";
import { Button, Grid, Header, Form, Checkbox } from "semantic-ui-react";
import { FORM_ERROR } from "final-form";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import TextInput from "../../../common/form/TextInput";
import ErrorMessage from "../../layout/ErrorMessage";
import { RootStoreContext } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";

interface IProps {
  partner: IPartnerData;
  isViewDetails: boolean;
}
const validateUpdate = combineValidators({
  name: isRequired("name"),
  organisation: isRequired("organisation"),
  
});

const ViewPartner: React.FC<IProps> = ({ partner, isViewDetails }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    updatePartner,
    handleUpdateClick,
    updatePartnerFlag,
    resetUpdatePartner,
    handleUpdateDepotBadgeNumClick,
    setFlags,
    partnerSignUpdate,
    handleUpdateSignHouseRulesClick,
    partnerEnterBadgeNumUpdate,
    ResendConfirmationEmail,
  } = rootStore.partnerStore;

  const handleEditPartner = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: IPartnerData
  ) => {
    event.preventDefault();
    handleUpdateClick();
  };

  // attendances: 0
  // badgeNumFlag: false

  // hasClockedOut: false
  // name: "Martin Carr"
  // organisation: "National Seaways"
  // signFlag: false
  // workId: "100"

  useEffect(() => {
  
    setFlags(partner.signFlag, partner.badgeNumFlag);
    return () => {
      resetUpdatePartner();
    };
  }, [resetUpdatePartner]);
  return (
    <div>
      <Grid centered>
        <Grid.Column width={15}>
          <Header as="h2" attached="top">
            {isViewDetails ? (
              <div>
                Edit <u>{partner?.name}</u> Details
              </div>
            ) : (
              <div>
                View <u>{partner?.name}</u> Details
              </div>
            )}
          </Header>

          <Segment clearing>
            <FinalForm
              onSubmit={(values: IPartnerData) =>
                updatePartner(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }))
              }
              initialValues={partner}
              validate={validateUpdate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error loading={submitting}>
                  <Field
                    name="id"
                    type="hidden"
                    defaultValue={partner?.id}
                    component={TextInput}
                  />

                  <Form.Group widths="equal">
                    <Field
                      label="Work ID"
                      name="workId"
                      placeholder="Work Id"
                      component={TextInput}
                      readOnly={true}
                    />
                    <Field
                      label="Name"
                      name="name"
                      placeholder="Name"
                      component={TextInput}
                      readOnly={!updatePartnerFlag}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Field
                      readOnly={!updatePartnerFlag}
                      label="Organisation"
                      name="organisation"
                      type="text"
                      placeholder="Organisation"
                      component={TextInput}
                    />
                    <Field
                      readOnly={true}
                      label="Num of Attendances"
                      name="attendances"
                      type="text"
                      placeholder="Num of Attendances"
                      component={TextInput}
                    />
                  </Form.Group>

                  <Form.Group>
           

                    {!updatePartnerFlag ? (
                      <Checkbox
                        toggle
                        readOnly={!updatePartnerFlag}
                        name="partnerSignUpdate"
                        checked={partner?.signFlag}
                        label="Sign House rules?"
                      />
                    ) : (
                      <Checkbox
                        onClick={handleUpdateSignHouseRulesClick}
                        toggle
                        name="partnerSignUpdate"
                        checked={partnerSignUpdate}
                        label="Sign House rules?"
                      />
                    )}
                  </Form.Group>
                  <Form.Group>
                    {!updatePartnerFlag ? (
                      <Checkbox
                        toggle
                        readOnly={!updatePartnerFlag}
                        name="partnerEnterBadgeNumUpdate"
                        checked={partner?.badgeNumFlag}
                        label="Enter Depot Badge?"
                      />
                    ) : (
                      <Checkbox
                        onClick={handleUpdateDepotBadgeNumClick}
                        toggle
                        name="partnerEnterBadgeNumUpdate"
                        checked={partnerEnterBadgeNumUpdate}
                        label="Enter Depot Badge?"
                      />
                    )}
                   
                  </Form.Group>

                         
                  {updatePartnerFlag ? (
                    <Button
                      fluid
                      icon="save"
                      loading={submitting}
                      disabled={
                        (invalid && !dirtySinceLastSubmit)
                      }
                      positive
                      labelPosition="left"
                      content="Save"
                    />
                   ) : (
                    <Button
                      fluid
                      icon="edit"
                      loading={submitting}
                      // disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                      primary
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleEditPartner(event, partner!)}
                      labelPosition="left"
                      content="Update"
                    />
                  )}

                  <br />

                          <Button
                            fluid
                            color="red"
                            content="Send QR Code"
                            icon="mail"
                            compact
                            onClick={ () => ResendConfirmationEmail(partner.workId)}
                            labelPosition="left"
                          />
                          <br/>
                  {submitError && !dirtySinceLastSubmit && (
                    <ErrorMessage error={submitError} />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default observer(ViewPartner);
