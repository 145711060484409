import React from "react";
import { Modal, Icon, Button,Header, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import QrReader from 'react-qr-reader';
import { RootStore, RootStoreContext } from "../../stores/rootStore";
import { useContext } from "react";



interface IProps {
    open: boolean;
  
    hasSignInValidation: (name: string) => void;
}
const SignInValidationModal: React.FC<IProps> = ({ open, hasSignInValidation }) => {

  const rootstore = useContext(RootStoreContext);
  const{SigninValidationModal} = rootstore.visitorStore
    return (
        <Modal  closeIcon={{ style: { top: '1.0535rem', right: '1rem' }, color: 'black', name: 'close' }} className="tempvalidation-option-modal" open={open} size="small" 
        dimmer="blurring" closeOnDimmerClick
       onClose= { SigninValidationModal}
        >
    
    <Modal.Header className = "validation-modal">
          <b>SIGN IN Warning !!!</b>
          </Modal.Header>

          <Modal.Content className = "signout-content">
          <Grid>
            
              <h3>
                
              You have Already Signed In. Do you want to Sign Out?
                  
                
              </h3>
              {/* <Image centered src={tempInstructions} /> */}
          
          </Grid>
        </Modal.Content>
          <Modal.Actions  className = "button-action">
            
            <Button  positive onClick={() => hasSignInValidation("yes")}>   
              Yes
            </Button>
            <Button   color="red" onClick={() => hasSignInValidation("no")}>
              No
            </Button>
          </Modal.Actions>
        </Modal>
        
    );
};

export default observer(SignInValidationModal) ;