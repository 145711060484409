import React from "react";
import { Modal, Icon, Button,Header } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RootStore, RootStoreContext } from "../../stores/rootStore";
import { useContext } from "react";


interface IProps{
    open  : boolean;

    hasSignOutReference: (name: string) => void;
}

const SignoutModal: React.FC<IProps> = ({open, hasSignOutReference}) => {

    const rootStore = useContext(RootStoreContext);
    const {closeSignoutModalQr} = rootStore.visitorStore;

    return(

        <Modal  closeIcon={{ style: { top: '1.0535rem', right: '1rem' }, color: 'black', name: 'close' }} className="code-option-modal" open={open} size="small" 
    dimmer="blurring" closeOnDimmerClick
   onClose= {closeSignoutModalQr}
    >

      <Modal.Header className = "headerqr">
        Do you have a QR/RV Code for Signout?. <br />
        
      </Modal.Header>
      <Modal.Actions  className = "button-action">
        
        <Button  positive onClick={() => hasSignOutReference("yes")}>   
          Yes
        </Button>
        <Button   color="red" onClick={() => hasSignOutReference("no")}>
          No
        </Button>
      </Modal.Actions>
    </Modal>

    );
}

export default observer(SignoutModal);