import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Modal } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";

const RemoveManager: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { RvMModal, repId, ManagerId, tenantId, closeRvMModal } = rootStore.modalStore;
  const { DeleteManager } = rootStore.managerStore;

  return (
    <Modal
      size="small"
      open={RvMModal}
      onClose={closeRvMModal}
      style={{
        height: 124,
        left: "auto",
        right: "auto",
        marginTop: 90,
      }}
    >
      <Modal.Header as="h2" content="Remove Manager?" />
      <Modal.Actions>
        <Button
          color="green"
          content="Yes"
          onClick={() => DeleteManager(ManagerId)}
        />
        <Button color="red" content="No" onClick={closeRvMModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default observer(RemoveManager);
