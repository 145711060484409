import { observable, action, runInAction } from "mobx";
import { IUser } from "../models/userModel";
import { RootStore } from "./rootStore";
import agents from "../API/agents";
import { CheckboxProps } from "semantic-ui-react";
import {
  IBookVisitFormData,
  IHost,
  IBookVisitSubmit,
  IPartnerFile,
} from "../models/bookVisitModel";
import { history } from "..";
import { combineDateTime, resetObject } from "../common/util/utils";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
 

export default class MeetingStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable user: IUser | null = null;
  @observable hostFlag: boolean = true;
  @observable badgeFlag: boolean = true;
  @observable printLabelFlag: boolean = false;
  @observable host: IUser | null = null;
  @observable regularVisitorFlag: boolean = false;
  @observable regVisitorRef: string = "";
  @observable dateError: string = "";
  @observable badgeNumFlag: boolean = false;
  @observable signFlag: boolean = false;
  @observable enterTemperature: boolean = false;
  @observable partnerFile: IPartnerFile | null = null;
  @observable axiosResponse: AxiosResponse | null = null;
  @observable errors: string = "";
  @observable axiosResponseSingle: AxiosResponse | null = null;
  @observable errorsSingle: string = "";
  @observable success: string = "";

  
  @action ChangePrintLabelFlag = async () => {
    runInAction(() => {
      this.printLabelFlag = !this.printLabelFlag;
    });
  };
  @action clearDateErrorMessage = () => {
    runInAction(() => {
      this.dateError = "";
    });
  };
  @action onChangeFlag = async (
    event: React.MouseEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    if (data.name === "badgeNumFlag")
      runInAction(() => (this.badgeNumFlag = !this.badgeNumFlag));

    if (data.name === "signFlag")
      runInAction(() => (this.signFlag = !this.signFlag));

      if (data.name === "enterTemperature")
      runInAction(() => (this.enterTemperature = !this.enterTemperature));
  };


  @action resetFlags = async() => 
  {
    this.badgeNumFlag = false;
    this.signFlag = false;
    this.enterTemperature = false;
    this.printLabelFlag = false;
  }
  @action BookMeeting = async (values: IBookVisitFormData) => {
    if (this.regularVisitorFlag) {
      try {
        values.enterTemperature =this.enterTemperature;
        values.signFlag = this.signFlag;
        values.badgeNumFlag = this.badgeNumFlag;
      //  console.log("enterTemperature", this.enterTemperature);

       // console.log("signflag", this.signFlag);
        
      //  console.log("badgeNum", this.badgeNumFlag);
        
        let host: IHost | null = null;
        if (this.hostFlag) {
          host = {
            name: this.rootStore.userStore.user?.name!,
            email: this.rootStore.userStore.user?.email!,
            department: this.rootStore.userStore.user?.department!,
          };
        } else {
          host = {
            name: values.hostName,
            email: values.hostEmail,
            department: values.department,
          };
        }
        const data: IBookVisitSubmit = {
          RegularVisitorRef: this.regVisitorRef,
          FirstName: values.AttendeeFirstName,
          LastName: values.AttendeeLastName,
          Email: values.AttendeeEmail,
          Company: values.AttendeeCompany,
          Host: host!,
          meetingRef: this.regVisitorRef,
          badgeID: values.badgeID,
          depot: this.rootStore.userStore.user?.depot.id!,
          printLabel: this.printLabelFlag,
          isRegularVisitor: this.regularVisitorFlag,
          passCode: values.passCode,
          signFlag: this.signFlag,
          enterTemperature: this.enterTemperature,
          badgeNumFlag: this.badgeNumFlag
        };

        if (!this.regularVisitorFlag)
          data.MeetingDateTime = combineDateTime(values.date!, values.time!).toString();

        const result = await agents.visitor.bookMeeting(data);
        if (result) {
          this.getRegVisitorRef();
          history.push(`/regular-visitor`);
          this.resetFlags();
          resetObject(values);
          toast.success("Regular Visitor Booked Successfully!");
        }
      } catch (error) {
    
        throw error;
      }
    } else {
      try {
        
        values.enterTemperature =this.enterTemperature;
        values.signFlag = this.signFlag;
        values.badgeNumFlag = this.badgeNumFlag;
        let host: IHost | null = null;
        if (this.hostFlag) {
          host = {
            name: this.rootStore.userStore.user?.name!,
            email: this.rootStore.userStore.user?.email!,
            department: this.rootStore.userStore.user?.department!,
          };
        } else {
          host = {
            name: values.hostName,
            email: values.hostEmail,
            department: values.department,
          };
        }

        const data: IBookVisitSubmit = {
          RegularVisitorRef: this.regVisitorRef,
          FirstName: values.AttendeeFirstName,
          LastName: values.AttendeeLastName,
          Email: values.AttendeeEmail,
          Company: values.AttendeeCompany,
          Host: host!,
          meetingRef: values.meetingRef,
          badgeID: values.badgeID,
          depot: this.rootStore.userStore.user?.depot.id!,
          printLabel: this.printLabelFlag,
          isRegularVisitor: this.regularVisitorFlag,
          passCode: values.passCode,
          signFlag: values.signFlag,
          enterTemperature: values.enterTemperature,
          badgeNumFlag: values.badgeNumFlag
        };

        if (!this.regularVisitorFlag)
          data.MeetingDateTime= combineDateTime(values.date!, values.time!).toString() ;
     
        const result = await agents.visitor.bookMeeting(data);

        if (result) {
          history.push(`/dashboard`);
          resetObject(values);
          toast.success("Visitor Booked Successfully!");
        }
      } catch (error) {
        throw error;
      }

      setTimeout(this.clearDateErrorMessage, 5000);
    }
  };

  @action getRegVisitorRef = async () => {
    try {
      let ref = await agents.regVisitor.getRegVisitorRef(this.rootStore.userStore.user?.tenantId!);
      this.regVisitorRef = ref;
    } catch (err) {
      throw err;
    }
  };
  @action changeRegularVisitorFlag = async () => {
    runInAction(() => {
      this.regularVisitorFlag = !this.regularVisitorFlag;
    });
  };
  @action changeHostFlag = async () => {
    runInAction(() => {
      this.hostFlag = !this.hostFlag;
      this.host = this.rootStore.commonStore.user;
    });
  };
  @action changeBadgeFlag = async () => {
    runInAction(() => {
      this.badgeFlag = !this.badgeFlag;
    });
  };
  @action LoadVisitorData = async (meetingId: string) => {
    try {
      await agents.visitor.mobileCheckIn(meetingId);
    } catch (err) {
      throw err;
    }
  };


}
