import React, { Fragment, useContext, useEffect } from "react";
import { Route, Switch } from "react-router";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import "./style/custom.css";
import VisitorPortal from "./components/visitor/VisitorPortal";
import PlannedVisit from "./components/visitor/PlannedVisit";
import Partners from "./components/Partner/Partners";
import UnplannedVisit from "./components/visitor/UnplannedVisit";
import Login from "./components/Authentication/Login";
import RegularVisitor from "./components/visitor/RegularVisitor";
import { RootStoreContext } from "./stores/rootStore";
import { ToastContainer } from "react-toastify";
import ResetPassword from "./components/Authentication/ResetPassword";
import VisitType from "./components/visitor/VisitType";
import Checkout from "./components/visitor/Checkout";
import QRCheckout from "./components/Modals/QRCheckout";
import ModalContainer from "./components/Modals/ModalContainer";
import EnterBadgeNumber from "./components/visitor/EnterBadgeNumber";
import DepotLogin from "./components/admin/DepotLogin";
import PrintHouseRules from "./components/visitor/PrintHouseRules";
import Register from "./components/admin/Register";
import AddDepot from "./components/admin/AddDepot";
import PageNotfound from "./components/PageNotFound";
import "mobx-react-lite/batchingForReactDom";
import PartnerPrintHouseRulesContainer from "./components/Partner/PartnerPrintHouseRulesContainer";
import PartnerTemp from "./components/Partner/PartnerTemp";
import PartnerAdmin from "./components/Partner/admin/PartnerAdmin";
import BookMeeting_NRV from "./components/admin/ManageVisitors/BookMeeting_NRV";
import BookMeeting_RV from "./components/admin/ManageVisitors/BookMeeting_RV";
import RegisterPartner from "./components/admin/ManageVisitors/RegisterPartner";
import PartnersReport from "./components/admin/ManageVisitors/PartnersReport";
import VisitorReports from "./components/admin/ManageVisitors/VisitorReports";
import AddTenant from "./components/admin/ManageTenant/AddTenant";
import ViewTenants from "./components/admin/ManageTenant/ViewTenants";
import ViewTenantDetails from "./components/admin/ManageTenant/ViewTenantDetails";
import UpdateTenant from "./components/admin/ManageTenant/UpdateTenant";
import BookedVisitors from './components/admin/ManageVisitors/BookedVisitors';
import PartnersRegistered from './components/admin/ManagePartners/PartnersRegistered';
import ForgotPassword from './components/Authentication/ForgotPassword'


const App = () => {
  const rootStore = useContext(RootStoreContext);
  const { token, setAppLoaded } = rootStore.commonStore;
  const { getUser } = rootStore.userStore;

  useEffect(() => {
    if (token) {
      getUser().finally(() => setAppLoaded());
    } else setAppLoaded();
  }, [token, getUser, setAppLoaded]);

  return (
    <Fragment>
      <ToastContainer position="bottom-right" />
      <ModalContainer />
      <Switch>
        {/* <Route exact path="/" component={DepotLogin} /> */}
        <Route exact path="/ml-admin" component={Login} />
        <Route
          exact
          path="/resetPassword/:passToken_block1/:passToken_block2/:passToken_block3/:username/:email"
          component={ResetPassword}
        />
        <Route
          exact
          path="/print-house-rules/:tenantName/:depotCode"
          component={PrintHouseRules}
        />
          <Route
          exact
          path="/print-house-rules/:tenantName/:depotCode/:regularFlag"
          component={PrintHouseRules}
        />
        <Route exact path="/visit-type/:tenantName/:depotCode" component={VisitType} />
        <Route exact path="/checkout/:tenantName/:depotCode"component={Checkout} />
        <Route
          exact
          path="/unplanned-visit/:tenantName/:depotCode"
          component={UnplannedVisit}
        />
        <Route exact path="/partner/:tenantName/:depotCode" component={Partners} />
        <Route
          exact
          path="/planned-visit/:tenantName/:depotCode"
          component={PlannedVisit}
        />
        <Route
          exact
          path="/regular-visitor/:tenantName/:depotCode"
          component={RegularVisitor}
        />
        <Route
          exact
          path="/partner-temperature/:tenantName/:depotCode"
          component={PartnerTemp}
        />
        <Route
          exact
          path="/enter-badge-number/:tenantName/:depotCode"
          component={EnterBadgeNumber}
        />
        <Route
          exact
          path="/enter-badge-number-partner/:tenantName/:depotCode/:origin"
          component={EnterBadgeNumber}
        />
        <Route
          exact
          path="/partner-sign-to-accept/:tenantName/:depotCode"
          component={PartnerPrintHouseRulesContainer}
        />
        <Route exact path="/partner-admin" component={PartnerAdmin} />
        <AuthorizeRoute path="/dashboard" component={BookMeeting_NRV} />
        <AuthorizeRoute path="/manage-users" component={Register} />
        <AuthorizeRoute path="/manage-depot" component={AddDepot} />
        {/* <AuthorizeRoute path="/add-badge" component={AddBadge} /> */}
        <AuthorizeRoute path="/reports" component={VisitorReports} />
        <AuthorizeRoute path="/partner-report" component={PartnersReport} />
        <AuthorizeRoute path="/planned-visitor" component={BookMeeting_NRV} />
        <AuthorizeRoute path="/regular-visitor" component={BookMeeting_RV} />
        <AuthorizeRoute path="/register-partner" component={RegisterPartner} />
        <AuthorizeRoute path="/manage-tenants" component={AddTenant} />
        <AuthorizeRoute path="/add-tenant" component={AddTenant} />
        <AuthorizeRoute path="/tenants" component={ViewTenants} />
        <AuthorizeRoute path="/tenant-details/:tenantId"  component={ViewTenantDetails}/>
        <AuthorizeRoute path='/booked-visitors' component={BookedVisitors} />
        <AuthorizeRoute path='/registered-partners'  component={PartnersRegistered} />
        <AuthorizeRoute path="/update-tenant-details" component={UpdateTenant} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route exact path="/:tenantName/:depotCode" component={VisitorPortal} />
        <Route exact path="/" component={DepotLogin} />
        <Route exact path="*" component={PageNotfound} />
      </Switch>
    </Fragment>
  );
};
export default App;
