import React from "react";
import { Modal, Icon, Button,Header, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import QrReader from 'react-qr-reader';
import { RootStore, RootStoreContext } from "../../stores/rootStore";
import { useContext } from "react";



interface IProps {
    open: boolean;
  
    accept: () => void;
}
  const DepotSignInWarning: React.FC<IProps> = ({ open, accept }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDepotwarningmodal } = rootStore.visitorStore;


    //const rootstore = useContext(RootStoreContext);
    //const{SignOutValidationModal} = rootstore.visitorStore

    return (
        <Modal  closeIcon={{ style: { top: '1.0535rem', right: '1rem' }, color: 'black', name: 'close' }} className="temp-modal" open={open} size="small" 
        dimmer="blurring" closeOnDimmerClick
        onClose= {closeDepotwarningmodal}
        >
    
          <Modal.Header className = "validation-modal">
          <b>SignIn Warning</b>
          </Modal.Header>

          <Modal.Content  className="temperature-modal">
          <Grid>
            
              <h2>
                You are already Signed-In at another Depot. Do you want to Sign-Out from there?
              </h2>
              {/* <Image centered src={tempInstructions} /> */}
          
          </Grid>
        </Modal.Content>
          <Modal.Actions className = "Temp-modal">
            
          <Button positive onClick={accept}>
            Yes
          </Button>
          </Modal.Actions>
        </Modal>
        
    );
};

export default observer(DepotSignInWarning) ;