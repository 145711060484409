import { ITenantRep } from "../../../models/tenant";
import { FORM_ERROR } from "final-form";
import React, { useContext, useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { Button, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import ErrorMessage from "../../layout/ErrorMessage";
import RemoveTenantRep from './RemoveTenantRep'
import TextInput from "../../../common/form/TextInput";
import { RootStoreContext } from "../../../stores/rootStore";
import "../../../style/rep.css";
import SelectInput from "../../../common/form/SelectInput";
// //http://revalidate.jeremyfairbank.com/common-validators/matchesField.html
const validate = combineValidators({
  name: isRequired("Name"),
  username: isRequired("Username"),
  email: isRequired("Email"),
  role: isRequired("Role"),

  // department: isRequired("Department")
});
interface IProps {
  rep: ITenantRep;
  isViewDetails: boolean;
}
const ViewRep: React.FC<IProps> = ({ rep, isViewDetails }) => {
  const rootStore = useContext(RootStoreContext);
  const { loadUserRole, userRoles } = rootStore.userStore;
  const { Edit, updateData, handleUpdateClick, ResendConfirmationEmail, removingRepLoading} = rootStore.repStore;
  const {openRepModal} = rootStore.modalStore
  const [loadingRoles, setLoadingRoles] = useState(true);

  const handleEditRep = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ITenantRep
  ) => {
    event.preventDefault();
    handleUpdateClick();
  };

  const handleRemoveRep = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ITenantRep
  ) => {
    event.preventDefault();
   openRepModal(data.id!, data.tenantId!)
  };

  const handleResendConfirmationEmailRep = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ITenantRep
  ) => {
    event.preventDefault();
    ResendConfirmationEmail(data.id!)
  };
  
  useEffect(() => {
    if (updateData) loadUserRole().then(()=> setLoadingRoles(false));
  }, [loadUserRole, updateData]);

  return (
    <div>
    <RemoveTenantRep />

      <Grid centered>
        <Grid.Column width={15}>
          {isViewDetails && (
            <Header as="h2" attached="top">
              {updateData ? (
                <div>
                  Edit <u>{rep?.name}</u> Details
                </div>
              ) : (
                <div>
                  View <u>{rep?.name}</u> Details
                </div>
              )}
            </Header>
          )}

          <Segment clearing>
            <FinalForm
              onSubmit={(values: ITenantRep) => Edit(values)}
              validate={validate}
              initialValues={rep}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error loading={submitting ||removingRepLoading }>
                  <Form.Group widths="equal">
                    {updateData ? (
                      <Field
                        loading={loadingRoles}
                        label="Role"
                        name="role"
                        placeholder="Role"
                        options={userRoles}
                        component={SelectInput}
                      />
                    ) : (
                      <Field
                        label="Role"
                        name="role"
                        placeholder="Role"
                        component={TextInput}
                        readOnly={!updateData}
                      />
                    )}
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Field
                      label="Name"
                      name="name"
                      placeholder="Name"
                      component={TextInput}
                      readOnly={!updateData}
                    />
                    <Field
                      label="Username"
                      name="username"
                      placeholder="Username"
                      component={TextInput}
                      readOnly={true}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
              

                    <Field
                      label="Email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      component={TextInput}
                      readOnly={!updateData}
                    />
                  </Form.Group>


                  <Field
                    name="id"
                    type='hidden'
                    defaultValue={rep.id}
                    
                    component={TextInput}
                  />  
                   <Field
                    name="tenantId"
                    type='hidden'
                    defaultValue={rep.tenantId}
                    
                    component={TextInput}
                  />  
                  <Grid columns="2">
                    <Grid.Column>
                      <Button
                        fluid
                        color="red"
                        content="Remove Rep."
                        icon="trash"
                        compact
                        labelPosition="left"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => handleRemoveRep(event, rep!)}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      {rep.emailConfirmed?.toString() === "false" ? (
                        <div>
                          <Button
                            fluid
                            color="yellow"
                            content="Send New Request"
                            icon="mail"
                            compact
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                            ) => handleResendConfirmationEmailRep(event, rep!)}
                            labelPosition="left"
                          />
                        </div>
                      ) : (
                        <p>Email is Confirmed!</p>
                      )}
                    </Grid.Column>
                  </Grid>
 

                  {submitError && !dirtySinceLastSubmit && (
                    <ErrorMessage error={submitError} />
                  )}
                  <br />
                  {updateData ? (
                    <Button
                      fluid
                      icon="save"
                      loading={submitting}
                      disabled={(invalid && !dirtySinceLastSubmit)}
                      positive
                      labelPosition="left"
                      content="Save"
                    />
                  ) : (
                    <Button
                      fluid
                      icon="edit"
                      loading={submitting}
                      // disabled={
                      //   (invalid && !dirtySinceLastSubmit) || pristine
                      // }
                      primary
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleEditRep(event, rep!)}
                      labelPosition="left"
                      content="Update"
                    />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default observer(ViewRep);
