import React, { useContext, useRef, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Form,
  Label,
  Icon,
  Input,
  Image,
  Modal,
} from "semantic-ui-react";
import QrReader from "react-qr-reader";
import { RootStoreContext } from "../../stores/rootStore";
import "react-simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";
import KeyboardReact from "react-simple-keyboard";
interface IProps {
  open: boolean;
  hasQRReference: (name: string) => void;
}
const QRCodeModal: React.FC<IProps> = ({ open, hasQRReference }) => {
  //const [findme_meetingref, setref] = useState('')
  const [layout, setLayout] = useState("default");
  const [openn, setOpen] = React.useState(false);
  const [inputName, setInputName] = useState("");
  const rootStore = useContext(RootStoreContext);
  const keyboard = useRef<any>();
  const setActiveInput = (event: any) => setInputName(event.target.id);
  const { closeQrcodemodal } = rootStore.visitorStore;
  const [timer, setTimer] = useState(null);

  
  let newkeyboard = {
    default: ["R 1 2 3", "V 4 5 6", "A 7 8 9", "{bksp} 0 {shift}"],
    shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]


  }
  const element = useRef(null);
  const {
    SearchQRInfo,
    CloseModalQR,
    SearchVisitByReference,
    onChangeSwitch,
    virtualKeyboardVisible,
    hideVirtualKeyboardVisible,
    findme_meetingref,
    qrcodeloading,
  } = rootStore.visitorStore;
  var tenant_name = rootStore.depotStore.depot?.tenantName!;
  var depot_code = rootStore.depotStore.depot?.code!;

  const handleShift = () =>
    setLayout(layout === "default" ? "shift" : "default");

  useEffect(() => {
    hideVirtualKeyboardVisible(window.innerWidth);
    document.getElementById("findme_meetingref")?.focus();

   // Qrcodetimeout();
  }, [hideVirtualKeyboardVisible]);

  const onKeyPress = (button: any) => {
    document.getElementById("findme_meetingref")?.focus();

    if (button === "{shift}" || button === "{lock}") handleShift();
  };
  const onChange = (input: any) => {
    onChangeSwitch(inputName, input);
  };
  const onChangeForm = (event: any) => {
    onChangeSwitch(event.target.name, event.target.value);
    keyboard.current.setInput(event.target.value);
  };

  
 
  const handleErrorWebcam = (error: any) => {
    console.log(error);
  };

  const handleScanWebcam = async(result: any) => {

    if (result) {
      CloseModalQR();
      SearchQRInfo(result);
    }

    
  };

  return (
    <div>
      <Modal
        closeIcon={{
          style: { top: "1.0535rem", right: "1rem" },
          color: "black",
          name: "close",
        }}
        className="code-QR-modal"
        size="small"
        open={open}
        onClose={closeQrcodemodal}
        //dimmer="blurring"
      >
        <Modal.Header className="headerqr">
          Please Scan QR Code To SignIn
        </Modal.Header>
        <Modal.Content className="QRCode">
          <Form className="qrCode_form">
            <QrReader
              className="ReaderQr"
              delay={100}
              style={{ width: "75%" }}
              onError={handleErrorWebcam}
              onScan={handleScanWebcam}
              resolution={600}
            />

            <br />
            <br />
            <br />

            <input
                required

              autoComplete={"off"}
              className="Qrinput"
              id="findme_meetingref"
              name="findme_meetingref"
              type="text"
              style={{ width: "255px", borderWidth: 2 }}
              value={findme_meetingref}
              onChange={onChangeForm}
              onFocus={setActiveInput}
              placeholder="Enter Reference No"
            />
          </Form>
          {virtualKeyboardVisible && (
            <div className="keyboardPosition1">
              <Keyboard
                inputName={inputName}
                useMouseEvents={true}
                keyboardRef={(r) => (keyboard.current = r)}
                layoutName={layout}
                onChange={onChange}
                onKeyPress={onKeyPress}

                layout = {newkeyboard }
                
                
                
              />
            </div>
          )}

          {!virtualKeyboardVisible && (
            <div>
              {" "}
              <br />
              <br />
              <br />
            </div>
          )}
        </Modal.Content>

        <Modal.Actions className="modal-actionqr">
          <Button color="red" onClick={() => hasQRReference("Cancel")}>
            {" "}
            <Icon name="remove" />
            Cancel
          </Button>
          <Button
          loading = {qrcodeloading}
            onClick={() => {
              hasQRReference("Find Me");
              SearchVisitByReference(
                tenant_name,
                depot_code,
                findme_meetingref
              );
            }}
            positive
          >
  
            {" "}
            <Icon name="checkmark" />
            {/* <Button   onClick={() => SearchQRInfo(findme_meetingref)} positive> <Icon name='checkmark' /> */}
            Find Me
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default observer(QRCodeModal);
