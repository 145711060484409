import { action, observable } from "mobx";
import { toast } from "react-toastify";
import agents from "../API/agents";
import {
  ITenant,
  ITenantDetailsResult,
  ITenantFormValues,
  ITenantRequest,
  ITenantResult,
} from "../models/tenant";
import { RootStore } from "./rootStore";
import { IDepotFormValues } from "../models/depot";
import { resetTenantObject } from "../common/util/utils";
import { ITenantList } from "../models/resetPasswordModel";
 

export default class TenantStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable tenantList: ITenantResult[] = [];
  @observable tenant: ITenantDetailsResult | null = null;
  @observable tenantDepots: IDepotFormValues[] = [];
  @observable tenantDepot: IDepotFormValues | null = null;
  @observable listTenants_shortlist: ITenantList[] = [];

  @action addTenant = async (data: ITenantFormValues) => {
    try {
      const tenantData: ITenant = {
        name: data.name,
        rep: {
          email: data.repEmail,
          name: data.repName,
          username: data.username,
        },
        depots: {
          code: data.code,
          line1: data.line1,
          line2: data.line2,
          county: data.county,
          eirCode: data.eirCode,
        },
      };
      var result = await agents.tenant.create(tenantData);
      if (result) {
        resetTenantObject(data);
        toast.success("Tenant successfully added!");
      }
    } catch (error) {
      throw error;
    }
  };
  @action listTenant = async () => {
    try {
      var result = await agents.tenant.list();
      if (result) {
        this.tenantList = result;
      }
    } catch (error) {
      throw error;
    }
  };
  @action shortListTenant = async () => {
    try {
      var result = await agents.tenant.shortList();
      if (result) {
        this.listTenants_shortlist = result;
      }
    } catch (error) {
      throw error;
    }
  };
  @action findTenant = async (data: ITenantRequest) => {
    try {
      
      if (this.rootStore.userStore.user?.role === "DepotManager") {
        const tenantObj = (this.tenant = {
          id: "",
          name: "",
          tenantRep: [],
          depots: [this.rootStore.userStore.user?.depot!],
        });
        this.tenant?.depots.map((e) => (e.readonly = true));
      } else {
        var result = await agents.tenant.find(data.tenantId);
        if (result) {
          this.tenant = result;
          this.tenant.depots.map((e) => (e.readonly = true));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  @action updateTenant = async (data: ITenant) => {};
  @action deleteTenant = async (tenantId: string) => {};
}
