import { th } from "date-fns/locale";
import { action, observable } from "mobx";
import { toast } from "react-toastify";
import agents from "../API/agents";
import {  ITenantRep } from "../models/tenant";
import { RootStore } from "./rootStore";

export default class RefStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }


  @observable updateData: boolean = false;
  @observable removingRepLoading: boolean = false;

  @action RemoveRep = async (repId: string, tenantId: string) => {
    this.updateData = false;
    this.removingRepLoading = true
    try {
      this.rootStore.modalStore.closeRepModal()
      const result = await agents.representative.remove(repId);

      if (result) {
        await this.rootStore.tenantStore.findTenant({ tenantId: tenantId! }).then(()=>this.removingRepLoading = false);
        this.rootStore.modalStore.closeModal();
      }
    } catch (error) {
      throw error;
    }
  };

  @action ResendConfirmationEmail = async (repId: string) => {
    try {
      const result = agents.representative.resendEmail(repId);
      if (await result) {
        this.rootStore.modalStore.closeModal();
        toast.info("New confirmation email sent!");
      }
    } catch (error) {
      throw error;
    }
  };

  @action Edit = async (data: ITenantRep) => {
    this.updateData = false;

    try {
      const result = await agents.representative.edit(data.id!, data);

      if (result) {
        await this.rootStore.tenantStore.findTenant({
          tenantId: data.tenantId!,
        });
        this.rootStore.modalStore.closeModal();
      }
    } catch (error) {
      throw error;
    }
    };
  @observable handleUpdateClick = () => {
    this.updateData = true;
  };
}
