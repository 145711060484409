import { createContext } from "react";
import UserStore from "./userStore";
import MeetingStore from "./meetingStore";
import DepotStore from "./depotStore";
import VisitorStore from "./visitorStore";
import CommonStore from "./commonStore";
import ReportStore from "./reportStore";
import ModalStore from "./modalStore";
import BadgeStore from "./badgeStore";
import TenantStore from "./tenantStore"
import RepStore from './RepStore'
import ManagerStore from './managerStore'
import PartnerStore from './partnerStore'
import RVStore from './rvStore'

export class RootStore {
  repStore: RepStore;
    userStore: UserStore;
    meetingStore: MeetingStore;
    depotStore: DepotStore;
    visitorStore: VisitorStore;
    commonStore: CommonStore;
    reportStore: ReportStore;
    modalStore: ModalStore;
    badgeStore: BadgeStore;
    tenantStore: TenantStore;
    managerStore: ManagerStore;
    partnerStore: PartnerStore;
    rvStore : RVStore;
 
    constructor() {
      this.partnerStore = new PartnerStore(this)
      this.managerStore = new ManagerStore(this);
      this.repStore = new RepStore(this);
      this.tenantStore = new TenantStore(this)
      this.reportStore = new ReportStore(this);
      this.commonStore = new CommonStore(this);
      this.meetingStore = new MeetingStore(this);
      this.userStore = new UserStore(this);
      this.depotStore = new DepotStore(this);
      this.visitorStore = new VisitorStore(this,this.reportStore,this.partnerStore);
      this.modalStore = new ModalStore(this);
      this.badgeStore = new BadgeStore(this);
      this.rvStore = new RVStore(this, this.visitorStore,this.reportStore);
    }
}

export const RootStoreContext = createContext(new RootStore());