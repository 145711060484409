import { action, observable, runInAction } from "mobx";
import agent from "../API/agents";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { IPartner, IPartnerData, IPartnerFile } from "../models/bookVisitModel";
import { AxiosResponse } from "axios";
import agents from "../API/agents";
import { resetPartnerObject } from "../common/util/utils";
import { history } from "..";
import { CheckboxProps } from "semantic-ui-react";
import VisitorStore from "./visitorStore";
import { IQrSendMail } from "../models/VisitorModel";
import { visitors } from "@babel/traverse";

export default class PartnerStore {
  rootStore: RootStore; 
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    //this.visitorstore = visitorstore;
  }

  @observable badgeNumFlag: boolean = false;
  @observable signFlag: boolean = false;
  @observable partnerFile: IPartnerFile | null = null;
  @observable axiosResponse: AxiosResponse | null = null;
  @observable errors: string = "";
  @observable axiosResponseSingle: AxiosResponse | null = null;
  @observable errorsSingle: string = "";
  @observable success: string = "";
  @observable bulkUploadLoading: boolean = false;
  // partner
  @observable workId: string = "";
  @observable partnerData: IPartner | null = null;
  @observable signInPartner: string = "";

  @observable signInOrganization: string = "";


  //Temp validation
  
  @observable TemperatureValidationModalPartner: boolean = false;

  @observable partnerModal: boolean = false;
  @observable partnerConfirmIdentity: boolean = false;
  //partner house rules
  @observable partnerHouseRules: boolean = false;

  @observable partnerClockInModal: boolean = false;
  @observable partnerClockOutModal: boolean = false;
  @observable proceedBtnPlannedVisitor: boolean = true;
  @observable openTempForm: boolean = false;
  @observable clearWorkIdRef: boolean = false;
  @observable clearBadgeNumRef: boolean = false;
  @observable openBadgeNumInput: boolean = false;
  @observable
  partnerBadgeErrorAxiosResponse: AxiosResponse | null = null;
  @observable partnerBadgeError: string = "";
  @observable updatePartnerFlag: boolean = false;
  @observable partnerSignUpdate: boolean = false;
  @observable partnerEnterBadgeNumUpdate: boolean = false;
  @observable temperature: string = "";
  @observable depotBadgeNum: string = "";

  @action setFlags = async (signFlag: boolean, badgeFlag: boolean) => {
    this.partnerSignUpdate = signFlag;
    this.partnerEnterBadgeNumUpdate = badgeFlag;
  };

  @observable depotSelected: string = "";

  @action setDepotSelected = (depot: string) => {
    this.depotSelected = depot;
  };
  @action handleUpdateSignHouseRulesClick = async () => {
    this.partnerSignUpdate = !this.partnerSignUpdate;
  };
  @action handleUpdateDepotBadgeNumClick = async () => {
    this.partnerEnterBadgeNumUpdate = !this.partnerEnterBadgeNumUpdate;
  };

  clearVisitorData = async () => {
    try {
      runInAction(() => {
        this.depotBadgeNum = "";
        this.temperature = "";
        this.workId = "";
        this.partnerModal = false;
        this.partnerConfirmIdentity = false;
        this.openTempForm = false;
        this.openBadgeNumInput = false;
        this.depotBadgeNum = "";
      });
    } catch (error) {
      throw error;
    }
  };

  @action onChangeSwitch = async (field: string, value: string) => {
    //resetting the virtual keyboard ref to initial state so
    //the system will allow the new input
    this.clearWorkIdRef = false;
    this.clearBadgeNumRef = false;
    switch (field) {
      case "partnerTemp":
        this.temperature = value;
        break;
      case "badgeNum":
        this.depotBadgeNum = value;
        break;
      case "workId":
        this.workId = value;
        break;
      default:
        console.log("select a field in the form");
    }
  };

  @action resetUpdatePartner = async () => (this.updatePartnerFlag = false);

  @action handleUpdateClick = async () => (this.updatePartnerFlag = true);

  partnerClockIn = async () => {
    try {
       await agents.partner.clockIn({
        workId: this.partnerData?.workId!,
        depot: this.rootStore.depotStore.depot?.id!,
        depotBadgeNum: this.depotBadgeNum,
        temperature: this.temperature,
      });
      if (this.partnerData?.signFlag) {
        history.push(
          `/partner-sign-to-accept/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      } else {
        this.partnerClockInModal = true;
        history.push(
          `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      }

      if (!this.partnerData?.signFlag) this.clearVisitorData();
    } catch (error) {
     // this.partnerBadgeErrorAxiosResponse = error;
      this.partnerBadgeError = this.partnerBadgeErrorAxiosResponse?.request.response;
      this.depotBadgeNum = "";
      this.clearBadgeNumRef = true;

      setTimeout(this.ClearErrorMessagePartnerBadgeError, 3000);
    } finally {
      if (this.partnerClockInModal) setTimeout(this.closeClockInModal, 9000);
    }
  };
  partnerClockOut = async (workId: string) => {
    try {
      const response = await agents.partner.clockOut({
        workId: workId!,
        depot: this.rootStore.depotStore.depot?.id!,
        depotBadgeNum: this.depotBadgeNum,
      });
      if (response) {
        this.signInPartner = response.name!;
        this.signInOrganization = response.organisation!;
        history.push(
          `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
        

        this.partnerClockOutModal = true;
      }else{
        toast.error("Invalid Reference Number");
      }
    } catch (error) {
      //this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;
      setTimeout(this.ClearErrorMessage, 3000);
    } finally {
      this.partnerModal = false;
      runInAction(() => {
        this.workId = "";
      });
      if (this.partnerClockOutModal) setTimeout(this.closeClockOutModal, 10000);
    }
  };
  @action ClearErrorMessagePartnerBadgeError = async () => {
    this.partnerBadgeError = "";
  };
  @action acceptIdentity = async () => {
    this.openTempForm = true;
  };
  @action partnerCloseClockInModal = () => {
    this.partnerClockInModal = false;
  };
  @action partnerCloseClockOutModal = () => {
    this.partnerClockOutModal = false;
  };
  @action partnerSubmitTemperature = () => (this.partnerHouseRules = true);


  @action partnerAcceptHouseRules = () => {
    this.partnerHouseRules = false;
    if (!this.partnerData?.badgeNumFlag && !this.partnerData?.signFlag) {
      this.partnerClockIn();

      history.push(
        `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
    } else if (this.partnerData?.badgeNumFlag) {
      this.openBadgeNumInput = true;
    } else if (!this.partnerData?.badgeNumFlag && this.partnerData?.signFlag) {
      this.partnerClockIn();
      history.push(
        `/partner-sign-to-accept/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
    }
  };
  closeClockOutModal = () => {
    runInAction(() => {
      this.partnerClockOutModal = false;
    });
  };
  closeClockInModal = () => {
    runInAction(() => {
      this.partnerClockInModal = false;
    });
  };
  @action PartnerPrintHouseRulesClick = async (signBlob?: any) => {
    try {
      const response = await agents.partner.partnerSignature({
        sign: signBlob,
        depotBadge: this.depotBadgeNum,
        workId: this.partnerData?.workId,
        depot: this.rootStore.depotStore.depot?.id!,
      });

      if (response) {
        this.openBadgeNumInput = false;
        this.partnerClockInModal = true;
        this.clearVisitorData();
        history.push(
          `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      }
    } catch (error) {
     // this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;
      setTimeout(this.ClearErrorMessage, 3000);
    } finally {
      this.partnerModal = false;
      this.workId = "";
      this.temperature = "";
      if (this.partnerClockInModal) setTimeout(this.closeClockInModal, 10000);
    }
  };
  @action closePartnerModal = async () => {
    this.partnerModal = false;
    this.openTempForm = false;
    this.temperature = "";
    this.workId = "";
    this.clearWorkIdRef = true;
    this.openBadgeNumInput = false;
    this.clearBadgeNumRef = true;
  };
  @action getPartner = async ( workId: string) => {
    try {
      const result = await agents.partner.findPartner(workId);

   
      console.log("Partnerdetails", result);
      if (result) {
        this.workId = workId;
        this.partnerData = result;
        this.signInPartner = result.name;
        this.signInOrganization = result.organisation;
        // if (!this.partnerData.hasClockedOut) this.partnerClockOut();
        // else this.partnerModal = true;
        this.partnerModal = true;
      }
    //  this.visitorstore.openVisitorQRCodeOption = false;
    } catch (error) {
      this.partnerModal = false;
      //this.axiosResponse = error;
      this.workId = "";
      this.errors = this.axiosResponse?.request.response;
      this.clearWorkIdRef = true;
      setTimeout(this.ClearErrorMessage, 3000);
    }
  };
  @action registerPartner = async (data: IPartner) => {
    try {
      data.signFlag = this.signFlag;
      data.badgeNumFlag = this.badgeNumFlag;
      data.depotId = this.rootStore.userStore.user?.depot.id;

      const result = await agents.partner.regPartner(data);

      if (result) {
        resetPartnerObject(data);
        toast.success("Partner Details Saved Successfully!");
        history.push("/register-partner");
      }
    } catch (error) {
     // this.axiosResponseSingle = error;
      this.errorsSingle = this.axiosResponse?.request?.response;
      setTimeout(this.ClearErrorMessage, 5000);
    }
  };
  @action setPartnerFile = async (file: Blob) => {
    this.partnerFile = {
      file: file,
      depotId: this.rootStore.userStore.user?.depot.id!,
    };
  };
  @action partnerSubmitBadgeNumber = async () => {
    await this.rootStore.partnerStore.partnerClockIn();
  };
  @action submitPartnerFile = async () => {
    try {
      this.bulkUploadLoading = true;
      const response = await agents.partner
        .fileUpload(this.partnerFile!)
        .then(() => (this.bulkUploadLoading = false));
        toast.success("File uploaded Successfully")

      if (response)
      {
        this.success = "File Uploaded Successfully!";
       
      }

      setTimeout(this.ClearSuccessMessage, 4000);
    } catch (error) {
      //this.axiosResponse = error;
      this.errors = this.axiosResponse?.request?.response;
      setTimeout(this.ClearErrorMessage, 5000);
      //throw error;
    }
    // setTimeout(this.errors = '', 4000)
    //
  };
  @action resetFlags = async() => 
  {
    this.badgeNumFlag = false;
    this.signFlag = false;
  }
  @action onChangeFlag = async (
    event: React.MouseEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    if (data.name === "badgeNumFlag")
      runInAction(() => (this.badgeNumFlag = !this.badgeNumFlag));

    if (data.name === "signFlag")
      runInAction(() => (this.signFlag = !this.signFlag));
  };
  @action ClearErrorMessage = async () => {
    this.errors = "";
  };
  @action ClearSuccessMessage = async () => {
    this.success = "";
  };

  @action updatePartner = async (data: IPartnerData) => {
    try {
      data.badgeNumFlag = this.partnerEnterBadgeNumUpdate;
      data.signFlag = this.partnerSignUpdate;

      const result = await agents.partner.edit(data.id, data);
      if (result) {
        await this.rootStore.reportStore.ListPartners(
          this.rootStore.userStore.user?.tenantId!,
          this.depotSelected
        );
        this.rootStore.modalStore.closeModal();
        toast.success("Partner successfully updated!");
      }
    } catch (error) {}
  };
 
  @action ResendConfirmationEmail = async (workid: string) => {

    let data: IQrSendMail ={
      meetingReference : workid
    }
    try{
      const result = await agents.partner.resendQRcode(workid, data);
      if(result)
      {
        this.rootStore.modalStore.closeModal();
        toast.info("New confirmation email sent!");
      }
    
    }  catch (error) {
      throw error;
    }
 }
  @action removePartner = async (partnerId: string) => {
    try {
      const result = await  agent.partner.delete(partnerId);
      if (result) {
       await this.rootStore.reportStore.ListPartners(
          this.rootStore.userStore.user?.tenantId!,
          this.depotSelected);
          toast.success("Partner successfully Deleted!");
         this.rootStore.modalStore.closePartnerModal();
      }
    } catch (error) {}
  };

  @action TempvalidationPartner = async () => {
    try {
      if (this.temperature > "39") {
        this.clearVisitorData();
        this.TemperatureValidationModalPartner = true;
      } else if (this.temperature < "33") {
        this.clearVisitorData();
        this.TemperatureValidationModalPartner = true;
      } else {
        this.partnerAcceptHouseRules();
      }
    } catch (error) {
      throw error;
    }
  };

  @action TemperatureValidationpartner = () => {
    this.TemperatureValidationModalPartner = false;

    history.push(
      `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
    );
  };
}
