import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const combineDateTime = (date: Date, time: Date) => {
  const dateString = date.toISOString().split("T")[0];
  const dateString1 = date.toLocaleDateString("en-CA");
  const sampledate = dateString1.replaceAll('/', '-');
  const timeString = time.toString().split(' ')[4];

  return sampledate + "T" + timeString + "Z";
};

export const ExportCSV = (dataObj: any, fileName: string) => {
  try {
 

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let date = new Date().getTime().toString();
    const ws = XLSX.utils.json_to_sheet(dataObj);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + date + fileExtension);
  } catch (error) {
    
  }
};

export const resetPartnerObject = (values: any) => {
  delete values.id;
  delete values.name;
  delete values.workId;
  delete values.organisation;
  delete values.hasClockedIn;
  delete values.hasClockedOut;
  // options
  delete values.badgeNumFlag;
  delete values.signFlag;
  delete values.depotBadgeNum;
};

export const resetObject = (values: any) => {
  delete values.AttendeeFirstName;
  delete values.AttendeeLastName;
  delete values.AttendeeCompany;
  delete values.AttendeeEmail;
  delete values.date;
  delete values.time;
  delete values.hostEmail;
  delete values.hostName;
  delete values.department;
  delete values.meetingRef;
  delete values.badgeID;
  delete values.depot;
  delete values.repeatPassCode;
  delete values.passCode;
  delete values.name;
  delete values.email;
  delete values.username;
  delete values.password;
  delete values.depotString;
  delete values.department;
  delete values.role;
  delete values.email_confirmation;
  delete values.enterTemperature;
  delete values.signFlag;
  delete values.badgeNumFlag;
  
};

export const resetDepotFormValuesObj = (values: any) => {
  delete values.code;
  delete values.line1;
  delete values.line2;
  delete values.county;
  delete values.eirCode;
};

export const resetTenantObject = (values: any) => {
  delete values.name;
  delete values.repName;
  delete values.repEmail;
  delete values.code;
  delete values.line1;
  delete values.line2;
  delete values.county;
  delete values.eirCode;
  delete values.repEmail_confirmation;
};
