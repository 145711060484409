import React from "react";
import { Modal, Icon, Button,Header } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import QrReader from 'react-qr-reader';
import { RootStore, RootStoreContext } from "../../stores/rootStore";
import { useContext } from "react";

interface IProps {
  open: boolean;
  
  hasReference: (name: string) => void;
}

const VisitorCodeOption: React.FC<IProps> = ({ open , hasReference}) => {

  const rootstore = useContext(RootStoreContext);
  const{signOptionModal} = rootstore.visitorStore
  return (
    <Modal  closeIcon={{ style: { top: '1.0535rem', right: '1rem' }, color: 'black', name: 'close' }} className="code-option-modal" open={open} size="small" 
    dimmer="blurring" closeOnDimmerClick
    onClose= {signOptionModal}
    >

      <Modal.Header className = "headerqr">
        Do you have a QR/RV Code?. <br />
        This may have been emailed to you?
      </Modal.Header>
      <Modal.Actions  className = "button-action">
        
        <Button  positive onClick={() => hasReference("yes")}>   
          Yes
        </Button>
        <Button   color="red" onClick={() => hasReference("no")}>
          No
        </Button>
      </Modal.Actions>
    </Modal>
    
  );
};

export default observer(VisitorCodeOption);
