import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Label } from "semantic-ui-react";
import { DateTimePicker } from "react-widgets";

interface IProps extends FieldRenderProps<Date, HTMLElement>, FormFieldProps {}

const TimeInput: React.FC<IProps> = ({
  input,
  width,
  date = false,
  time = false,
  placeholder,
  meta: touched,
  error,
  id,
  ...props
}) => {
  return (
    <Form.Field
      className="fix-position-moz-DateTimePicker"
      error={touched && error}
      width={width}
    >
      <DateTimePicker
        id={String(id)}
        placeholder={placeholder}
        value={input.value || null}
        onChange={input.onChange}
        {...props} // Push for fix date having -1 day the time is also out by 1 hour due to daylight savings
        //onBlur={input.onBlur}
        onKeyDown={(e) => e.preventDefault()}
        date={date}
        time={time}
      />
      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default TimeInput;
