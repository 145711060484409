import React, { useContext, useState, useRef, useEffect } from "react";
import { Button, Grid, Image, Form, Card } from "semantic-ui-react";
import logo from "../../assets/visIT_logo.png";
import { RootStoreContext } from "../../stores/rootStore";
import Keyboard from "react-simple-keyboard";
import { observer } from "mobx-react-lite";
import ErrorMessage from "../layout/ErrorMessage";
import "../../style/visitor.css";
import PartnerHouseRules from "./PartnerHouseRules";
import TemperatureValidationMessage from "../Modals/TemperatureValidationModal";
import { history } from "../..";
const Partners = (props) => {
  const rootStore = useContext(RootStoreContext);
  const {
    getPartner,
    partnerBadgeErrorAxiosResponse,
    partnerBadgeError,
    acceptIdentity,
    onChangeSwitch,
    closePartnerModal,
    errors,
    partnerSubmitTemperature,
    TempvalidationPartner,
    TemperatureValidationpartner,
    TemperatureValidationModalPartner,
    clearVisitorData,
    workId,
    depotBadgeNum,
    temperature,
    partnerHouseRules,
    partnerSubmitBadgeNumber,
    axiosResponse,
    partnerModal,
    partnerData,
    openBadgeNumInput,
    openTempForm,
    clearWorkIdRef,
    clearBadgeNumRef,
  } = rootStore.partnerStore;

  const { depot, getDepot } = rootStore.depotStore;
  const [inputName, setInputName] = useState("");
  const keyboard = useRef();
  const setActiveInput = (event) => setInputName(event.target.id);
  const [layout, setLayout] = useState("default");

  const BackHomeClick = () => {
    clearVisitorData();
    history.push(`/${tenantName}/${depotCode}`);
  };
  const { match } = props;
  let { depotCode, tenantName } = match.params;

 useEffect(() => {
     //clearVisitorData();
    if (workId === '') 
        document.getElementById("workId").focus();
    if (partnerBadgeError) keyboard.current.clearInput("badgeNum");
    if (!depot) getDepot(tenantName, depotCode);
    if (errors) keyboard.current.clearInput("workId");
    if (clearBadgeNumRef) keyboard.current.clearInput("badgeNum");
    if (partnerBadgeError) {
      keyboard.current.clearInput("badgeNum");
      document.getElementById("badgeNum").focus();
    }
  if (clearWorkIdRef)
   keyboard.current.clearInput("workId");
  }, [
    errors,
    depotCode,
    tenantName,
    depot,
    getDepot,
   // clearVisitorData,
    clearBadgeNumRef,
    partnerBadgeError,
    workId,
  ]);

  const handleShift = () =>
    setLayout(layout === "default" ? "shift" : "default");

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleSubmitTemp = (event) => {
    event.preventDefault();     
    TempvalidationPartner();
    //partnerSubmitTemperature();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    getPartner();
  };

  const handleSubmitBadgeNum = (event) => {
    event.preventDefault();
    partnerSubmitBadgeNumber();
  };

  const onChangeForm = (event) => {
    onChangeSwitch(event.target.name, event.target.value);
    keyboard.current.setInput(event.target.value);
  };
  const onChange = (input) => {
    onChangeSwitch(inputName, input);
  };

  return (
    <div>
      <Button
        style={{ marginBottom: 15, marginLeft: 25, marginTop: 20 }}
        size="big"
        icon="home"
        onClick={BackHomeClick}
        default
        content="Home"
      />

      {/* <ConfirmIdentity /> */}
      <PartnerHouseRules open={partnerHouseRules} />

      <TemperatureValidationMessage
      open= {TemperatureValidationModalPartner}
      accept={TemperatureValidationpartner}
      />

      <Grid columns={2} centered style={{ marginTop: 5 }}>
        <Image
          src={logo}
          style={{
            marginTop: 20,
            marginBottom: 25,
          }}
        />
      </Grid>
      <Grid centered>
        <Form onSubmit={handleSubmit} error method="post">
          <Grid.Row>
            <Form.Group widths="equal">
              <Form.Field>
                <input
                  required
                  style={{ fontSize: 18, borderWidth: 2 }}
                  id="workId"
                  name="workId"
                  type="text"
                  value={workId}
                  readOnly={openTempForm}
                  onChange={onChangeForm}
                  placeholder="Work Id"
                  onFocus={setActiveInput}
                  autoComplete={"off"}
                />
              </Form.Field>
              <Form.Field>
                <Button
                  style={{
                    marginBottom: 15,
                    marginLeft: 25,
                    backgroundColor: "#4DB25E",
                    color: "#f3f3f3",
                  }}
                  size="big"
                  icon="checkmark"
                  disabled={partnerModal}
                  content="Check In/Out"
                />
              </Form.Field>
            </Form.Group>
          </Grid.Row>
          <Form.Field>
            <Grid.Row>
              {errors && <ErrorMessage error={axiosResponse} text={errors} />}
            </Grid.Row>
          </Form.Field>
        </Form>

        {partnerModal && (
          <Grid.Row columns="1" centered>
            <Grid.Column width="4">
              <Card fluid color="red">
                <Card.Content>
                  <Grid columns="3">
                    <Grid.Column width="10" style={{ fontSize: 15 }}>
                      Please Confirm your Identity:
                      <br />
                      <strong>{partnerData?.name}</strong>
                    </Grid.Column>
                    <Grid.Column width="6">
                      <Button
                        floated="right"
                        negative
                        content="No"
                        onClick={closePartnerModal}
                      />
                      <Button
                        positive
                        content="Yes"
                        floated="right"
                        onClick={acceptIdentity}
                      />
                    </Grid.Column>
                  </Grid>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        )}
        {openTempForm && (
          <Grid.Row>
            <Form onSubmit={handleSubmitTemp} error method="post">
              <Grid.Row>
                <Form.Group widths="equal">
                  <Form.Field>
                    <input
                      required
                      style={{ fontSize: 18, borderWidth: 2 }}
                      id="partnerTemp"
                      name="partnerTemp"
                      type="text"
                      value={temperature}
                      onChange={onChangeForm}
                      placeholder="Temperature"
                      onFocus={setActiveInput}
                      autoComplete={"off"}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button
                      style={{
                        marginBottom: 15,
                        marginLeft: 25,
                        backgroundColor: "#4DB25E",
                        color: "#f3f3f3",
                      }}
                      size="big"
                      icon="sign-in"
                      primary
                      disabled={temperature === "" || openBadgeNumInput}
                      content="Submit Temp."
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Row>
            </Form>
          </Grid.Row>
        )}
        <br />

        {openBadgeNumInput && (
          <Grid.Row>
            <Form onSubmit={handleSubmitBadgeNum} error method="post">
              <Grid.Row>
                <Form.Group widths="equal">
                  <Form.Field>
                    <input
                      required
                      style={{ fontSize: 18, borderWidth: 2 }}
                      id="badgeNum"
                      name="badgeNum"
                      type="text"
                      value={depotBadgeNum}
                      onChange={onChangeForm}
                      placeholder="Badge Number"
                      onFocus={setActiveInput}
                      autoComplete={"off"}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Button
                      icon="sign-in"
                      style={{
                        marginBottom: 15,
                        marginLeft: 25,
                        width: 190,
                        backgroundColor: "#4DB25E",
                        color: "#f3f3f3",
                      }}
                      size="big"
                      primary
                      content="Submit"
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Row>
              <Form.Field>
                <Grid.Row>
                  {partnerBadgeError && (
                    <ErrorMessage
                      error={partnerBadgeErrorAxiosResponse}
                      text={partnerBadgeError}
                    />
                  )}
                </Grid.Row>
              </Form.Field>
            </Form>
          </Grid.Row>
        )}
      </Grid>

      <div className="keyboardPosition">
        <Keyboard
          inputName={inputName}
          useMouseEvents={true}
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName={layout}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    </div>
  );
};

export default observer(Partners);
