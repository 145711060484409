import React, { useState, useRef, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Header, Icon, Modal } from "semantic-ui-react";
import QrReader, { props } from "react-qr-reader";
import { RootStoreContext } from "../../stores/rootStore";
import CheckoutMessage from "./CheckoutMessage";
import "react-simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";
import { event } from "react-ga";

interface IProps {
  open: boolean;
  hasCheckoutRef: (name: string) => void;
}
const QRCheckout: React.FC<IProps> = ({ open, hasCheckoutRef }, props) => {
  const rootStore = useContext(RootStoreContext);
  const keyboard = useRef<any>();
  const setActiveInput = (event: any) => setInputName(event.target.id);
  const [inputName, setInputName] = useState("");
  const [layout, setLayout] = useState("default");
  const { closeQrcodecheckoutmodal, CloseModalQRcheckout } = rootStore.visitorStore;
  //const [findme_meetingref, setref] = useState('')

  let newkeyboard = {
    default: ["R 1 2 3", "V 4 5 6", "A 7 8 9", "{bksp} 0 {shift}"],
    shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]


  }

  const {
    VisitorQRCheckOut,
    checkoutWindow,
    CheckOutQRCode,
    onChangeSwitch,
    virtualKeyboardVisible,
    hideVirtualKeyboardVisible,
    findme_meetingref,
  } = rootStore.visitorStore;

  var tenant_name = rootStore.depotStore.depot?.tenantName!;
  var depot_code = rootStore.depotStore.depot?.id!;
  const handleShift = () =>
    setLayout(layout === "default" ? "shift" : "default");

  useEffect(() => {
    hideVirtualKeyboardVisible(window.innerWidth); 
    
  }, [hideVirtualKeyboardVisible]);


  const onKeyPress = (button: any) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
    document.getElementById("findme_meetingref")?.focus();

  };
  const onChange = (input: any) => {
    onChangeSwitch(inputName, input);
  };
  const onChangeForm = (event: any) => {
    onChangeSwitch(event.target.name, event.target.value);
    keyboard.current.setInput(event.target.value);

  };

  const handleErrorWebcam = (error: any) => {
    console.log(error);
  };
  const handleScanWebcam = (result: any) => {
    if (result) {
      CloseModalQRcheckout()
      CheckOutQRCode(result);
    }
  };
  const setLoadTextBox = () => 
  {
    document.getElementById("findme_meetingref")?.focus();
  }

  return (
    <Modal
      open={open}
      // dimmer="blurring"
      closeIcon={{
        style: { top: "1.0535rem", right: "1rem" },
        color: "black",
        name: "close",
      }}
      size="small"
      className="code-QR-modal"
      onClose={closeQrcodecheckoutmodal}
    >
      <CheckoutMessage open={checkoutWindow} />
      <Modal.Header className="headerqr">Please Scan QR Code to SignOut </Modal.Header>
      <Modal.Content className="QRCode">
        <Form className="qrCode_form">
          <QrReader
            className="ReaderQr"
            delay={100}
            style={{ width: "75%" }}
            onError={handleErrorWebcam}
            onScan={handleScanWebcam}
          />
          <br />
          <br />
          <br />
          <Form.Field>
          <input
            required
            autoComplete={"off"}
            className="Qrinput"
            id="findme_meetingref"
            name="findme_meetingref"
            type="text"
            style={{ width: "255px" , borderWidth: 2 }}
            value={findme_meetingref}
            onChange={onChangeForm}
            onFocus={setActiveInput}
            placeholder="Enter Reference No"
          />
           </Form.Field>
        </Form>

        {virtualKeyboardVisible && (
          <div className="keyboardPosition1">
            <Keyboard
              inputName={inputName}
              useMouseEvents={true}
              keyboardRef={(r) => (keyboard.current = r)}
              layoutName={layout}
              onChange={onChange}
              onKeyPress={onKeyPress}

              layout= {newkeyboard}
              
            />
            
          </div>
        )}
        {!virtualKeyboardVisible && (
          <div>
            {" "}
            <br />
            <br />
            <br />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions className="modal-actionqr">
        <Button
          className="cancel-btn"
          color="red"
          onClick={() => hasCheckoutRef("Cancel")}
        >
          <Icon name="remove" /> Cancel
        </Button>
        <Button
          className="checkout-btn"
          color="green"
          onClick={() => {
            CheckOutQRCode(findme_meetingref);
            hasCheckoutRef("Checkout");
          }}
        >
          <Icon name="checkmark" /> Checkout
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default observer(QRCheckout);
