import React, {
  useContext,
  useState,
  useRef,
  Fragment,
  useEffect,
} from "react";
import { Button, Grid, Image, Form } from "semantic-ui-react";
import logo from "../../assets/visIT_logo.png";
import { RootStoreContext } from "../../stores/rootStore";
import Keyboard from "react-simple-keyboard";
import CheckoutMessage from "../Modals/CheckoutMessage";
import { observer } from "mobx-react-lite";
import ErrorMessage from "../layout/ErrorMessage";
import { history } from "../..";
import BatchNumberValidation from "../Modals/BatchNumberValidation";
import LoadingComponent from "../Modals/LoadingComponents";




const Checkout = (props) => {
  const [badgeNum, setBadgeNum] = useState("");
  const rootStore = useContext(RootStoreContext);
  const {
    VisitorCheckOut,
    VisitorQRCheckOut,
    checkoutWindow,
    errors,
    axiosResponse,
    clearVisitorData,
    virtualKeyboardVisible,
    BatchNumberMessageValidation,
    BatchNumberMessage,
    loading
  } = rootStore.visitorStore;

 const [inputName, setInputName] = useState("");
 const keyboard = useRef();
  const setActiveInput = (event) => setInputName(event.target.id);
  const [layout, setLayout] = useState("default");
  const { match } = props;
  const { depot, getDepot } = rootStore.depotStore;
  let { depotCode, tenantName } = match.params;

  
  useEffect(() => {
    document.getElementById("badgeNum").focus();
    if (!depot) getDepot(tenantName, depotCode);
  }, [depot, getDepot, tenantName, depotCode]);

  const onChangeBadgeNum = (event) => {
    const input = event.target.value.toUpperCase();
    setBadgeNum(input);
   keyboard.current.setInput(input);
  };

  const handleShift = () =>
    setLayout(layout === "default" ? "shift" : "default");

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const BackHomeClick = () => {
    clearVisitorData();
    history.push(`/${depot.tenantName}/${depot.code}`);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    VisitorCheckOut(badgeNum, depot.id);
  };

  const onChange = (input) => {
    if (typeof input !== "string");
    setBadgeNum(input);
  };


  // const handleScanWebcam = (result) => {
  //   if(result)
  //   {
      
  //     VisitorQRCheckOut(result, depot.id);
  //   }
      
  // };
  if(loading) return <LoadingComponent content ="Please Wait"/>
  return (
    <Fragment>
       <BatchNumberValidation
      open= {BatchNumberMessage}
      accept={BatchNumberMessageValidation}
      />
      <CheckoutMessage open={checkoutWindow} />
      <Button
        style={{ marginBottom: 15, marginLeft: 25, marginTop: 20 }}
        size="big"
        icon="home"
        onClick={BackHomeClick}
        default
        content="Home"
      />
      <Grid columns={2} centered style={{ marginTop: 5 }}>
        <Image
          src={logo}
          style={{
            marginTop: 20,
            marginBottom: 25,
          }}
        />
        <br />
      </Grid>
      
      <Grid columns={1} centered>
        <Grid.Column width={4}>
          <Grid.Row centered>
            <Form onSubmit={handleSubmit} error method="post">
              <Grid.Row>
                <Form.Field>
                  <input
                    required
                    style={{ fontSize: 18, borderWidth: 2 , marginLeft:0}}
                    id="badgeNum"
                    name="badgeNum"
                    type="text"
                    value={badgeNum}
                    onChange={onChangeBadgeNum}
                    placeholder="Badge Number"
                    onFocus={setActiveInput}
                    autoComplete={"off"}
                  />
                    
                        
                </Form.Field>

                <Button
                  style={{ marginBottom: 15, marginLeft: 32 }}
                  size="big"
                  icon="sign-out"
                  primary
                  content="Check Out"
                />
                    
                    <br/>
                    <br/>
                    <br/>
                    

                    {/* <QrReader
                  delay={100}
                  style={{ width: '40%', marginLeft: 130 }}
                  onScan={handleScanWebcam}
                  /> */}
                    
                 
              </Grid.Row>
              <Grid.Row centered>
              <Form.Field>
                        <div>
                          {" "}
                          <br />
                          <br />
                          <br />
                        </div>
                   {/* <MuiNumpad
                    onChange={onChange}
                /> */}
                </Form.Field>
                </Grid.Row>
            </Form>
          </Grid.Row>
          {errors && <ErrorMessage error={axiosResponse} text={errors} />}
        </Grid.Column>
      </Grid>

      {virtualKeyboardVisible && (
        <div className="keyboardPosition">
          <Keyboard
            inputName={inputName}
            useMouseEvents={true}
            keyboardRef={(r) => (keyboard.current = r)}
            layoutName={layout}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </div>
      )}
      {!virtualKeyboardVisible && (
        <div>
          {" "}
          <br />
          <br />
          <br />
        </div>
      )}
    </Fragment>
  );
};

export default observer(Checkout);
