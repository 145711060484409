import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Table,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/rootStore";
import ManageTenantLayout from "../../layout/ManageTenantLayout";
import { Field, Form as FinalForm } from "react-final-form";
import TextInput from "../../../common/form/TextInput";
import ErrorMessage from "../../layout/ErrorMessage";
import { IDepotsDetails } from "../../../models/tenant";
import { observer } from "mobx-react-lite";
import AddDepot from "../AddDepot";
import Register from "../Register";
import AddBadge from "../AddBadge";
import ViewBadge from "../ViewBadge";
import AddManager from "../AddManager";
import "../../../style/viewTenantDetails.css";
import { Redirect } from "react-router-dom";
import ViewRep from "./ViewRep";
const ViewTenantDetails: React.FC = (props: any) => {
  const rootStore = useContext(RootStoreContext);
  const { tenant, findTenant } = rootStore.tenantStore;
  const { updateDepot, setLocationReactOnlyFalse } = rootStore.depotStore;
  const { openModal } = rootStore.modalStore;
  const { user } = rootStore.userStore;
  const [loading, setLoading] = useState(true);
  const [roleArr] = useState(["TenantAdmin", "SuperAdmin", "DepotManager"]);
  const { match } = props;
  let { tenantId } = match.params;

  const handleEditLocations = (event: any, data: IDepotsDetails) => {
    event.preventDefault();
    setLocationReactOnlyFalse(data);
  };

  useEffect(() => {
    findTenant({ tenantId: tenantId }).then(() => setLoading(false));

    return () => {};
  }, [findTenant, tenantId, setLoading]);

  if (!roleArr.includes(user?.role!)) return <Redirect to="/" />;
  return (
    <ManageTenantLayout>
      {user?.role !== "DepotManager" && (
        <Segment loading={loading}>
          <Header as="h2" content={tenant?.name} dividing />
          <Segment>
            <Button
              compact
              labelPosition="left"
              color="teal"
              content="Add Representative"
              icon="plus"
              onClick={() =>
                openModal(
                  // register a rep for this tenant
                  <Register tenantId={tenant?.id} tenantName={tenant?.name} />
                )
              }
            />
            <Button
              compact
              color="yellow"
              labelPosition="left"
              onClick={() =>
                openModal(
                  <AddDepot tenantId={tenantId} tenantName={tenant?.name} />
                )
              }
              content="Add Location"
              icon="plus"
            />
          </Segment>

          <Header as="h3" content="Users" dividing />
          <Table color="teal">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Username</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                {/* <Table.HeaderCell>Email Confirmed</Table.HeaderCell> */}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {tenant?.tenantRep &&
                tenant?.tenantRep.map((e, i) => (
                  <Table.Row key={i.toString()}>
                    <Table.Cell>{e.name}</Table.Cell>
                    <Table.Cell>{e.username}</Table.Cell>
                    <Table.Cell>{e.email}</Table.Cell>
                    <Table.Cell>{e.role}</Table.Cell>
                    <Table.Cell>
                      <Button
                        compact
                        content="View"
                        icon="eye"
                        color="orange"
                        labelPosition="left"
                        onClick={() =>
                          openModal(<ViewRep rep={e} isViewDetails={true} />)
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Segment>
      )}

      <Segment loading={loading}>
        <h4>Location(s)</h4>
        <br />

        <Grid
          className={
            tenant?.depots.length === 1
              ? "grid-container-single-Item"
              : "grid-container"
          }
        >
          {tenant?.depots &&
            tenant?.depots.map((e: IDepotsDetails, i) => (
              <Grid.Row key={i.toString()} centered>
                <Grid.Column width="7">
                  <h4>Address</h4>
                  <br />
                  <br />
                  <FinalForm
                    key={i.toString()}
                    onSubmit={(values: IDepotsDetails) => updateDepot(values)}
                    initialValues={e}
                    // validate={validate}
                    render={({
                      handleSubmit,
                      submitting,
                      submitError,
                      invalid,
                      pristine,
                      dirtySinceLastSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit} error loading={submitting}>
                        <Segment clearing color="yellow">
                          <Field
                            label="Code"
                            name="code"
                            readOnly={true}
                            component={TextInput}
                          />
                          <Field
                            name="id"
                            type="hidden"
                            component={TextInput}
                          />
                          <Form.Group widths="equal">
                            <Field
                              name="address.line1"
                              readOnly={e.readonly}
                              type="text"
                              component={TextInput}
                            />
                            <Field
                              name="address.line2"
                              readOnly={e.readonly}
                              type="text"
                              component={TextInput}
                            />
                          </Form.Group>
                          <Form.Group widths="equal">
                            <Field
                              name="address.county"
                              readOnly={e.readonly}
                              type="text"
                              component={TextInput}
                            />
                            <Field
                              name="address.eirCode"
                              readOnly={e.readonly}
                              type="text"
                              component={TextInput}
                            />
                          </Form.Group>
                          {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage error={submitError} />
                          )}
                          {e.readonly ? (
                            <Button
                              icon="edit"
                              onClick={(
                                event: React.MouseEvent<
                                  HTMLButtonElement,
                                  MouseEvent
                                >
                              ) => handleEditLocations(event, e)}
                              primary
                              loading={submitting}
                              labelPosition="left"
                              content="Update"
                            />
                          ) : (
                            <Button
                              icon="save"
                              positive
                              loading={submitting}
                              labelPosition="left"
                              content="Save"
                            />
                          )}
                        </Segment>
                      </Form>
                    )}
                  />
                </Grid.Column>
                <Grid.Column width="4">
                  <h4>Badges</h4>
                  <div className="addDetailsHeader">
                    <Button
                      color="green"
                      compact
                      labelPosition="left"
                      onClick={() =>
                        openModal(
                          <AddBadge
                            depot={e}
                            tenantId={tenantId}
                            tenantName={tenant?.name}
                          />
                        )
                      }
                      content="Add Badge"
                      icon="plus"
                    />
                  </div>
                  <div className={"badges-box"}>
                    <Table color="green">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell content="Number" />
                          <Table.HeaderCell content="In Use?" />
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {e?.badges.map((b, i) => (
                          <Table.Row key={i.toString()}>
                            <Table.Cell content={b.number} />
                            <Table.Cell>
                              {b.inUse === false ? (
                                <Icon color="red" name="thumbs down outline" />
                              ) : (
                                <Icon color="green" name="thumbs up outline" />
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {b.inUse.toString() === "true" ? (
                                <Button
                                  compact
                                  icon="eye"
                                  labelPosition="left"
                                  color="orange"
                                  onClick={() =>
                                    openModal(
                                      <ViewBadge
                                        tenantId={tenantId}
                                        depotId={e.id}
                                        badgeNum={b.number!}
                                      />
                                    )
                                  }
                                  content="View"
                                />
                              ) : null}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid.Column>
                <Grid.Column width="4">
                  <h4>Managers</h4>

                  <div className="addDetailsHeader">
                    <Button
                      compact
                      color="red"
                      labelPosition="left"
                      onClick={() =>
                        openModal(
                          <AddManager
                            tenantId={tenantId}
                            depot={e}
                            tenantName={tenant?.name}
                          />
                        )
                      }
                      content="Add Manager"
                      icon="plus"
                    />
                  </div>

                  <div className={e?.badges ? "badges-box" : ""}>
                    <Table color="red">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell content="Name" />
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {e?.managers.map((m, i) => (
                          <Table.Row key={i.toString()}>
                            <Table.Cell content={m.name} />
                            <Table.Cell>
                              <Button
                                compact
                                color="orange"
                                icon="eye"
                                onClick={() =>
                                  openModal(
                                    <AddManager
                                      manager={m}
                                      tenantId={tenantId}
                                      isViewDetails={true}
                                    />
                                  )
                                }
                                content="View"
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid.Column>
              </Grid.Row>
            ))}
        </Grid>
      </Segment>
    </ManageTenantLayout>
  );
};

export default observer(ViewTenantDetails);
