import { RootStore } from "./rootStore";
import { observable, action } from "mobx";

export default class ModalStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable modal = {
    open: false,
    body: null,
  };

  @observable partnerModal = false;
  @observable partnerId = ''


  @observable repModal = false;
  @observable RvModal = false;
  @observable RvMModal = false;

  @observable repId = ''
  @observable tenantId = ''
  @observable AttendeeId =''
  @observable ManagerId =''
  @observable depotId =''



 @action openRepModal = (repId: string, tenantId: string) => {
    this.repId = repId
    this.tenantId = tenantId
    this.repModal = !this.repModal;
  };

  @action openRvModal = (AttendeeId: string, tenantId: string) => {
    this.AttendeeId = AttendeeId
    this.tenantId = tenantId
    this.RvModal = !this.RvModal;
  };

  @action openRvMModal = (Id: string, depotId: string) => {
    this.ManagerId = Id;
    this.depotId = depotId;
    this.RvMModal = !this.RvMModal;
  };


  

  @action closeRepModal = () => this.repModal = false
  @action closeRvModal = () => this.RvModal = false
  @action closeRvMModal = () => this.RvMModal = false

  


  @action openPartnerModal = (partnerId: string) => {
    this.partnerId = partnerId
    this.partnerModal = !this.partnerModal;
  };


  @action closePartnerModal = () => this.partnerModal = false


  @action openModal = (content: any) => {
    this.modal.open = true;
    this.modal.body = content;
  };
  @action closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };
}
