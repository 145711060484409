import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
 
import { Modal, Button } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";

const PartnerClockOutModal = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    partnerClockOutModal,
    partnerData,
    signInOrganization,
    signInPartner,
    partnerCloseClockOutModal,
  } = rootStore.partnerStore;

  return (
    <Modal
      className="partner-clock-in-modal"
      open={partnerClockOutModal}
      size="mini"
    >
      <Modal.Header>Check-Out</Modal.Header>
      <Modal.Content>
        <h3>
          Thank you <u>{signInPartner}</u> from{" "}
          <u>{signInOrganization}</u>.
        </h3>
        <h3>You have now checked out.</h3>
    
      </Modal.Content>

      <div className="modal-action">
        <Button
        fluid
          content="Close"
          color="green"
          onClick={partnerCloseClockOutModal}
        />
      </div>
     
    </Modal>
  );
};

export default observer(PartnerClockOutModal);
