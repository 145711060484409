import { observable, action, runInAction } from "mobx";
import agent from "../API/agents";

import { RootStore } from "./rootStore";

import PartnerStore from "./partnerStore";
import {
  IVisitorModel,
  IVisitorModel1,
  ICheckInAttendee,
  IPassCode,
  IEditRV,
  IBookedVisitorsReturnLists,
} from "../models/VisitorModel";

import { Bounce, Slide, toast } from "react-toastify";
import { history } from "..";
import agents from "../API/agents";
import { CheckboxProps } from "semantic-ui-react";
import React, { useState, SyntheticEvent } from "react";
import { IVReason } from "../models/visitReason";
import { IEmailNotification } from "../models/userModel";
import { AxiosResponse } from "axios";
import ReportStore from "./reportStore";

export default class VisitorStore {
  rootStore: RootStore;
  partnerstore: PartnerStore;
  reportStore: ReportStore;
  constructor(rootStore: RootStore, reportStore: ReportStore, partnerstore: PartnerStore) {
    this.rootStore = rootStore;
    this.partnerstore = partnerstore;
    this.reportStore = reportStore;
  }

  @observable EnterTemperature: boolean = false;
  @observable signFlag: boolean = false;
  @observable SignFlag: boolean = false;

  @observable badgeNumFlag: boolean = false;

  @observable hasCheckedout: boolean = false;
  @observable hasCheckedin: boolean = false;

  @observable vReasonList: IVReason[] = [];
  // modals flags
  @observable checkoutWindow: boolean = false;
  @observable checkInWindow: boolean = false;
  @observable qrcodeloading: boolean = false;

  @observable securityRulesWindow: boolean = false;
  @observable bookedVisitorsReturnList: IBookedVisitorsReturnLists | null =
    null;
  @observable houseRulesWindow: boolean = false;
  @observable otherRulesWindow: boolean = false;
  @observable healthSafetyWindow: boolean = false;

  @observable regularvisitorbtn: boolean = true;
  @observable loading: boolean = false;

  @observable TemperatureValidationModal: boolean = false;
  @observable DepotSignInWarningModal: boolean = false;

  @observable BatchNumberMessage: boolean = false;


  @observable scrollDownFlag: boolean = false;

  @observable visitor: ICheckInAttendee | null = null;
  @observable firstName: string = "";
  @observable dateofvisit: Date = new Date();
  @observable lastName: string = "";
  @observable email: string = "";
  @observable reason: string = "";
  @observable company: string = "";
  @observable carReg: string = "";
  @observable escort: string = "";
  @observable meetingWith: string = "";
  @observable department: string = "";
  @observable Rvcode: string = "";

  @observable highlightInputBorderCarReg: boolean = false;
  @observable highlightInputBorderTemp: boolean = false;

  // reg visitor ref
  @observable regVisitorRef: string = "";
  @observable meetingRefOrBadgeNum: string = "";
  @observable scanwebcam: string = "";
  @observable depotBadgeNum: string = "";
  @observable temperature: string = "0.0";

  @observable findme_meetingref: string = "";

  // need to make it generic

  // covid self declaration form
  @observable one_ans: string = ""!;
  @observable two_ans: string = "";
  @observable three_ans: string = "";
  @observable four_ans: string = "";
  @observable five_ans: string = "";
  @observable six_ans: string = "";
  @observable visitPath: string = "";

  // regular visitor
  @observable _GlobalPassCodeArr: number[] = [];
  @observable passCode_char_1_index: string = "";
  @observable passCode_char_2_index: string = "";
  @observable passCode_char_3_index: string = "";

  @observable passCode_char_1_val: string = "";
  @observable passCode_char_2_val: string = "";
  @observable passCode_char_3_val: string = "";

  // house rules
  @observable hr_1: boolean = false;
  @observable hr_2: boolean = false;
  @observable hr_3: boolean = false;
  @observable hr_4: boolean = false;
  @observable hr_5: boolean = false;
  @observable hr_proceedBtn: boolean = false;

  @observable virtualKeyboardVisible: boolean = true;

  // security Rules
  @observable sr_1: boolean = false;
  @observable sr_2: string = "";
  @observable sr_proceedBtn: boolean = false;

  // HEALTH AND SAFETY
  @observable hsr_1: string = "";
  @observable hsr_2: string = "";
  @observable hsr_3: string = "";
  @observable hsr_4: string = "";
  @observable hsr_5: string = "";
  @observable hsr_6: string = "";
  @observable hsr_proceedBtn: boolean = false;

  //QR CODE

  @observable MeetingRef: string = "";
  @observable Visitype: string = "";

  //other rules
  @observable or_1: string = "";
  @observable or_2: string = "";
  @observable or_proceedBtn: boolean = false;

  @observable hidePrintContent: boolean = true;

  @observable axiosResponse: AxiosResponse | null = null;
  @observable errors: string = "";

  @observable formIsValid: boolean = false;

  @observable openVisitorCodeOption: boolean = false;
  @observable QRCodeOptionModal: boolean = false;
  @observable SignoutModalQR: boolean = false;

  @observable openVisitorQRCodeOption: boolean = false;
  @observable visitorName: string = "";
  @observable signInVisitorName: string = "";

  @observable openVisitorCodeOptionRegular: boolean = false;

  @observable openSignInValidationModal: boolean = false;
  @observable openSignOutValidationModal: boolean = false;

  @observable disableFindMeBtm: boolean = true;
  @observable unplannedProceedBtn: boolean = true;

  @observable temInstructionsFlag1: boolean = true;
  @observable temInstructions: boolean = false;
  @observable findMeFlag: boolean = false;

  //open instructions window
  @action openTempInstructionWin = async () => {
    runInAction(() => (this.temInstructions = true));
  };
  @action closeTemWindow = async () => {
    runInAction(() => {
      this.temInstructions = false;
      this.temInstructionsFlag1 = false;
    });
  };

  @action closeQrcodemodal = () => {
    this.openVisitorQRCodeOption = false;
    this.findme_meetingref = "";
    // clearTimeout();
  };

  @action closeDepotwarningmodal = () => {
    this.DepotSignInWarningModal = false;
    this.findme_meetingref = "";
  };

  @action closeSignoutModalQr = () => {
    this.SignoutModalQR = false;
  };

  @action closeQrcodecheckoutmodal = () => {
    this.QRCodeOptionModal = false;
    this.findme_meetingref = "";
    //clearTimeout();
  };

  @action signOptionModal = () => {
    this.openVisitorCodeOption = false;
  };

  @action SignOutValidationModal = () => {
    this.openSignOutValidationModal = false;
  };

  @action SigninValidationModal = () => {
    this.openSignInValidationModal = false;
  };

  @action TemperatureValidation = () => {
    this.TemperatureValidationModal = false;

    history.push(
      `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
    );
  };

  @action BatchNumberMessageValidation = () => {
    this.BatchNumberMessage = false;

    history.push(
      `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
    );
  };
  @action DepotSignInWarningModalValidation = () => {
    this.DepotSignInWarningModal = false;
    this.QRCodeOptionModal = true;

    // history.push(
    //   `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
    // );
  };

  @action Qrcodetimeout = () => {
    let timertime: any;

    timertime = setTimeout(() => {
      this.closeQrcodemodal();
      //setTimer(null);
    }, 8000);
    //setTimer(timertime)

    return () => {
      clearTimeout(timertime);
    };
  };

  @action Qrcodesignouttimeout = () => {
    let timertime: any;

    timertime = setTimeout(() => {
      this.closeQrcodecheckoutmodal();
      // setTimer(null);
    }, 8000);
    //setTimer(timertime)

    return () => {
      clearTimeout(timertime);
    };
  };

  @action openVisitorCodeOptionModal = async (e: any, d: any) => {
    switch (d.name) {
      case "planned":
        this.openVisitorCodeOption = true;
        // this.Qrcodetimeout()
        break;
      case "regular":
        this.QRCodeOptionModal = true;
        break;
      case "checkout":
        this.QRCodeOptionModal = true;
        break;
      case "signoout":
        this.SignoutModalQR = true;
        // this.Qrcodesignouttimeout()
        break;
      default:
        break;
    }
  };

  @action hasCheckoutRef = async (option: string) => {
    switch (option) {
      case "Cancel":
        this.QRCodeOptionModal = false;
        this.findme_meetingref = "";
        break;
      case "Checkout":
        break;
      default:
        break;
    }
  };

  @action hasReference = async (option: string) => {
    switch (option) {
      case "yes":
        this.openVisitorCodeOption = false;
        this.openVisitorQRCodeOption = true;       
        // history.push(
        //   `/planned-visit/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        // );
        break;
      case "no":
        //this.QRCodeOptionModal = true;
        this.openVisitorCodeOption = false;
        history.push(
          `/unplanned-visit/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
        break;
      case "Cancel":
        this.openVisitorCodeOption = false;
      default:
        break;
    }
  };

  @action hasSignoutModal = async (option: string) => {
    switch (option) {
      case "yes":
        this.SignoutModalQR = false;
        this.QRCodeOptionModal = true;
        break;
      case "no":
        this.SignoutModalQR = false;
        history.push(
          `/checkout/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
    }
  };

  @action hasSignInValidation = async (option: string) => {
    switch (option) {
      case "yes":
        this.openSignInValidationModal = false;
        this.QRCodeOptionModal = true;
        // this.SignoutModalQR = false;
        //this.QRCodeOptionModal = true;
        break;
      case "no":
        this.openSignInValidationModal = false;
    }
  };

  @action hasSignOutValidation = async (option: string) => {
    switch (option) {
      case "yes":
        this.openSignOutValidationModal = false;
        this.openVisitorQRCodeOption = true;
        // this.SignoutModalQR = false;
        //this.QRCodeOptionModal = true;
        break;
      case "no":
        this.openSignOutValidationModal = false;
    }
  };

  @action hasQRReference = async (option: string) => {
    switch (option) {
      case "Cancel":
        this.openVisitorQRCodeOption = false;
         this.findme_meetingref = "";
        // history.push(
        //   `/planned-visit/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        // );
        break;
      case "Find Me":
        this.openVisitorCodeOption = false;
        this.openVisitorQRCodeOption = false;

        break;
      default:
        break;
    }
  };

  @action hasReferenceRegular = async (option: string) => {
    switch (option) {
      case "yes":
        this.openVisitorCodeOptionRegular = false;
        history.push(
          `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
        break;
      case "no":
        this.openVisitorCodeOptionRegular = false;
        history.push(
          `/unplanned-visit/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
        break;
      default:
        break;
    }
  };
  // Other RULES
  @action onchangeOtherRules = async (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "or_1":
        this.or_1 = data.value as string;

        break;
      case "or_2":
        this.or_2 = data.value as string;

        break;
      default:
        break;
    }
    runInAction(() => {
      this.or_proceedBtn = this.or_1.length > 0 && this.or_2.length > 0;
    });
  };
  // HEALTH AND SAFETY
  @action onchangeHealthSafetyRules = async (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "hsr_1":
        this.hsr_1 = data.value as string;

        break;
      case "hsr_2":
        this.hsr_2 = data.value as string;

        break;
      case "hsr_3":
        this.hsr_3 = data.value as string;

        break;
      case "hsr_4":
        this.hsr_4 = data.value as string;

        break;
      case "hsr_5":
        this.hsr_5 = data.value as string;

        break;
      case "hsr_6":
        this.hsr_6 = data.value as string;

        break;
      case "hr_5":
        this.hr_5 = true;
        break;
      case "or_1":
        this.or_1 = data.value as string;
        break;
      case "or_2":
        this.or_2 = data.value as string;
        break;

      default:
        console.log("Unknown Option!");
        break;
    }

    this.hsr_proceedBtn =
      this.hsr_1.length > 0 &&
      this.hsr_2.length > 0 &&
      this.hsr_3.length > 0 &&
      this.hsr_4.length > 0 &&
      this.hr_4 &&
      this.hr_5 &&
      this.hsr_6.length > 0 &&
      this.or_1.length > 0 &&
      this.or_2.length > 0;
  };
  // SECURITY RULES
  @action onchangeSecurityRules = async (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "sr_1":
        this.sr_1 = true;

        break;
      case "sr_2":
        this.sr_2 = data.value as string;

        break;
      default:
        break;
    }

    this.sr_proceedBtn = this.sr_1 && this.sr_2.length > 0;
  };
  // house rules
  @action onchangeHouseRules = async (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "hr_1":
        this.hr_1 = true;

        break;
      case "hr_2":
        this.hr_2 = true;

        break;
      case "hr_3":
        this.hr_3 = true;

        break;
      case "hr_4":
        this.hr_4 = true;

        break;
      case "hr_5":
        this.hr_5 = true;
        break;
      case "sr_1":
        this.sr_1 = true;

        break;
      case "sr_2":
        this.sr_2 = data.value as string;

        break;

      default:
        break;
    }

    this.hr_proceedBtn =
      this.hr_1 &&
      this.hr_2 &&
      this.hr_3 &&
      this.hsr_5.length > 0 &&
      this.sr_1 &&
      this.sr_2.length > 0;
  };
  @action GenerateRequiredCharactersForPassCode = async () => {
    var numArr: number[] = [];
    while (numArr.length < 3) {
      var r: number = Math.floor(Math.random() * 6) + 1;
      if (!numArr.includes(r)) numArr.push(r);
    }

    //sort the num array
    numArr.sort();
    switch (numArr[0]) {
      case 1:
        this.passCode_char_1_index = 1 + "st";

        break;
      case 2:
        this.passCode_char_1_index = 2 + "nd";

        break;
      case 3:
        this.passCode_char_1_index = 3 + "rd";

        break;
      default:
        this.passCode_char_1_index = numArr[0] + "th";

        break;
    }

    switch (numArr[1]) {
      case 1:
        this.passCode_char_2_index = 1 + "st";

        break;
      case 2:
        this.passCode_char_2_index = 2 + "nd";

        break;
      case 3:
        this.passCode_char_2_index = 3 + "rd";

        break;
      default:
        this.passCode_char_2_index = numArr[1] + "th";

        break;
    }

    switch (numArr[2]) {
      case 1:
        this.passCode_char_3_index = 1 + "st";

        break;
      case 2:
        this.passCode_char_3_index = 2 + "nd";

        break;
      case 3:
        this.passCode_char_3_index = 3 + "rd";

        break;
      default:
        this.passCode_char_3_index = numArr[2] + "th";

        break;
    }

    this._GlobalPassCodeArr = numArr;
  };
  // load reason for visit
  @action LoadReasonForVisit = async () => {
    try {
      const response = await agent.visitReason.getVReasons();
      if (response) this.vReasonList = response;
    } catch (error) {
      throw error;
    }
  };
  @action CheckboxChange = async (
    event: SyntheticEvent,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "q1":
        this.one_ans = data.value as string;

        break;
      case "q2":
        this.two_ans = data.value as string;

        break;
      case "q3":
        this.three_ans = data.value as string;

        break;
      case "q4":
        this.four_ans = data.value as string;

        break;
      case "q5":
        this.five_ans = data.value as string;

        break;
      case "q6":
        this.six_ans = data.value as string;

        break;
      default:
        console.log("[ERROR]: CheckboxChange");
        break;
    }
  };
  @action ChangeReasonForVisit = async (reason: string) => {
    this.reason = reason;

    this.unplannedValidateForm();
  };
  @action onChangeSwitch = async (field: string, value: string) => {
    switch (field) {
      case "partnerTemp":
        this.temperature = value;
        break;
      case "fName":
        this.firstName =
          (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "lName":
        this.lastName =
          (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "email":
        this.email = value;
        break;
      case "company":
        this.company =
          (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "carReg":
        this.carReg = value.toUpperCase();
        //this.highlightInputBorderCarReg = false;
        break;
      case "escort":
        this.escort = (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "meetingWith":
        this.meetingWith =
          (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "department":
        this.department = value.toUpperCase();
        break;
      case "meetingRefOrBadgeNum":
        this.meetingRefOrBadgeNum = value.toUpperCase();
        break;
      case "scanwebcam":
        this.scanwebcam = value.toUpperCase();
        break;
      case "regVisitorRef":
        this.regVisitorRef = value.toUpperCase();
        break;
      case "findme_meetingref":
        this.findme_meetingref = value.toUpperCase();
        break;
      case "badgeNum":
        this.depotBadgeNum = value;
        break;
      case "passCode_val1":
        this.passCode_char_1_val = value;
        break;
      case "passCode_val2":
        this.passCode_char_2_val = value;
        break;
      case "passCode_val3":
        this.passCode_char_3_val = value;
        break;
      case "temperature":
        this.temperature = value;
        this.highlightInputBorderTemp = false;

        break;
      default:
        console.log("select a field in the form");
    }

    this.validateForm();
    this.unplannedValidateForm();
  };

  @action SearchVisitByBadgeNum = async (
    tenantName: string,
    depotCode: string
  ) => {
    try {
      const visitor = await agents.visitor.searchByMeetingRefOrBadgeID({
        meetingRef: this.meetingRefOrBadgeNum,
        tenantName: tenantName,
        depotCode: depotCode,
      });

      if (visitor) {
        this.visitor = visitor;
        this.firstName = visitor.firstName;
        this.lastName = visitor.lastName;
        this.email = visitor.email;
        this.company = visitor.company;
        this.department = visitor.host.department;
        this.meetingWith = visitor.host.name;
        this.highlightInputBorderCarReg = true;
        this.highlightInputBorderTemp = true;
      }
    } catch (error) {
      this.clearVisitorData();
      //////////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  // SEARCH VISITOR USING TEXTBOX AND BUTTON FIND ME

  @action SearchVisitByReference = async (
    tenantName: string,
    depotCode: string,
    meetingRef: string
  ) => {
    try {
      this.loading = true;
      var depot_code = this.rootStore.depotStore.depot?.id!;
      var info = await agents.visitor.QRInfo(meetingRef);
      this.loading = false;

      if (info.visittype == "Regular") {
        const visitor = await agents.visitor.searchByQRCode({
          meetingRef: meetingRef,
          tenantName: tenantName,
          depotCode: depotCode,

          //  PassCode:
        });
        this.hasCheckedin = visitor.hasCheckedIn!;
        this.hasCheckedout = visitor.hasCheckedOut!;
        if (
          visitor.depotId != depot_code &&
          this.hasCheckedin == true &&
          this.hasCheckedout == false
        ) {
          this.DepotSignInWarningModal = true;
        } else if (this.hasCheckedin == true && this.hasCheckedout == false) {
          this.openSignInValidationModal = true;
          this.openVisitorQRCodeOption = false;
        } else if (this.hasCheckedin == true && this.hasCheckedout == true) {
          history.push(
            `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );

          if (visitor) {
            this.regVisitorRef = meetingRef;
            this.visitor = visitor;
          this.signInVisitorName = visitor.firstName + " " + visitor.lastName;
            this.firstName = visitor.firstName;
            this.lastName = visitor.lastName;
            this.email = visitor.email;
            this.company = visitor.company;
            this.department = visitor.host.department;
            this.meetingWith = visitor.host.name;
            this.highlightInputBorderCarReg = true;
            this.highlightInputBorderTemp = true;
            this.findMeFlag = true;
            this.badgeNumFlag = visitor.badgeNumFlag;
            this.EnterTemperature = visitor.enterTemperature;
            this.signFlag = visitor.signFlag;
            this.carReg = visitor.carReg!;
          }
          this.temInstructions = true;
          this.loading = false;
        } else {
          history.push(
            `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );
          this.regVisitorRef = meetingRef;
          this.visitor = visitor;
          this.firstName = visitor.firstName;
          this.lastName = visitor.lastName;
          this.signInVisitorName = visitor.firstName + " " + visitor.lastName;

          this.email = visitor.email;
          this.company = visitor.company;
          this.department = visitor.host.department;
          this.meetingWith = visitor.host.name;
          this.highlightInputBorderCarReg = true;
          this.highlightInputBorderTemp = true;
          this.findMeFlag = true;
          this.EnterTemperature = visitor.enterTemperature;
          this.signFlag = visitor.signFlag;
          this.badgeNumFlag = visitor.badgeNumFlag;
          this.loading = false;
        }
      } else if (info.visittype == "Planned") {
        var visitor = await agents.visitor.searchByMeetingRefOrBadgeID({
          meetingRef: meetingRef,
          tenantName: tenantName,
          depotCode: depotCode,
        });
        this.hasCheckedin = visitor.hasCheckedIn!;
        this.hasCheckedout = visitor.hasCheckedOut!;

        if (this.hasCheckedin == true && this.hasCheckedout == true) {
          toast.error(" Reference Number Already Used");
        } else {
          history.push(
            `/planned-visit/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );

          this.SearchVisitByQrCode(tenantName, depotCode, meetingRef);
        }
      } else if (info.visittype == "Partner") {
        var result = await agent.partner.findPartner(meetingRef);
        var newdate = new Date(result.clockIn);

        var date2 =
          newdate.getFullYear() +
          "-" +
          (newdate.getMonth() + 1) +
          "-" +
          newdate.getDate();

        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var dateTime = date;

        if (
          date2 != dateTime &&
          result.hasClockedIn == true &&
          result.hasClockedOut == false
        ) {
          history.push(
            `/partner/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );
          this.loading = false;
          this.partnerstore.getPartner(meetingRef);
        } else if (
          date2 == dateTime &&
          result.hasClockedIn == true &&
          result.hasClockedOut == false
        ) {
          this.openSignInValidationModal = true;
          this.openVisitorQRCodeOption = false;
        } else if (
          result.hasClockedIn == true &&
          result.hasClockedOut == true
        ) {
          history.push(
            `/partner/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );
          this.loading = false;
          this.partnerstore.getPartner(meetingRef);
        } else {
          this.CloseModalQRcheckout();

          history.push(
            `/partner/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );
          this.loading = false;
          this.partnerstore.getPartner(meetingRef);
        }
      } else {
        this.loading = false;
        this.CloseModalQRcheckout();
        toast.error("Wrong Reference Number Entered");
      }

      this.findme_meetingref = "";
    } catch (error) {
      this.clearVisitorData();
      ////////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  //QR FOR PLANNED VISITOR TO IDENTIFY IF QR CODE IS VALID OR NOT

  @action SearchVisitByQrCode = async (
    tenantName: string,
    depotCode: string,
    meetingRef: string
  ) => {
    try {
      this.loading = false;
      var visitor = await agents.visitor.searchByMeetingRefOrBadgeID({
        meetingRef: meetingRef,
        tenantName: tenantName,
        depotCode: depotCode,
      });

      var newdate = new Date(visitor.dateOfVisit);
      var date2 =
        newdate.getFullYear() +
        "-" +
        (newdate.getMonth() + 1) +
        "-" +
        newdate.getDate();

      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var dateTime = date;

      if (date2 == dateTime) {
        this.openVisitorQRCodeOption = false;
        this.meetingRefOrBadgeNum = visitor.visitorRef;
        this.firstName = visitor.firstName;
        this.lastName = visitor.lastName;
        this.signInVisitorName = visitor.firstName + " " + visitor.lastName;
        this.email = visitor.email;
        this.company = visitor.company;
        this.department = visitor.host.department;
        this.meetingWith = visitor.host.name;
        this.highlightInputBorderCarReg = true;
        this.highlightInputBorderTemp = true;
        this.badgeNumFlag = visitor.badgeNumFlag;
        this.EnterTemperature = visitor.enterTemperature;
        this.signFlag = visitor.signFlag;
        this.loading = false;
      } else {
        this.loading = false;
        toast.info("No Meeting Scheduled For Your Reference Number");
        history.push(`/${tenantName}/${depotCode}`);
      }
    } catch (error) {
      this.clearVisitorData();
      ////////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  @action CheckOutQRCode = async (meetingReference: string) => {
    var tenant_name = this.rootStore.depotStore.depot?.tenantName!;
    var depot_code = this.rootStore.depotStore.depot?.id!;

    try {
      this.loading = true;
      var info = await agents.visitor.QRInfo(meetingReference);

      if (info.visittype == "Regular") {
        var info = await agents.visitor.QRInfo(meetingReference);
        this.CloseModalQRcheckout();

        this.VisitorQRCheckOut(meetingReference, depot_code);
      } else if (info.visittype == "Planned") {
        this.CloseModalQRcheckout();
        var info = await agents.visitor.QRInfo(meetingReference);

        this.VisitorQRCheckOut(meetingReference, depot_code);
      } else if (info.visittype == "Partner") {
        var result = await agent.partner.findPartner(meetingReference);
        this.loading = false;

        if (result.hasClockedIn == true && result.hasClockedOut == true) {
          this.openSignOutValidationModal = true;
          this.openVisitorQRCodeOption = false;
          this.QRCodeOptionModal = false;
        } else if (
          result.hasClockedIn == true &&
          result.hasClockedOut == false
        ) {
          this.CloseModalQRcheckout();
          // this.loading=false;

          this.partnerstore.partnerClockOut(meetingReference);
        }
      } else {
        this.loading = false;
        this.CloseModalQRcheckout();
        toast.error("Wrong Reference Number Entered");
      }

      this.findme_meetingref = "";
    } catch (error) {
      throw error;
    }
  };
  //QR ALL TYPE OF VISITORS USING MODAL SCREEN
  @action SearchQRInfo = async (meetingReference: string) => {
    var tenant_name = this.rootStore.depotStore.depot?.tenantName!;
    var depot_code = this.rootStore.depotStore.depot?.code!;
    try {
      this.loading = true;
      var info = await agents.visitor.QRInfo(meetingReference);
      this.loading = false;

      if (info.visittype == "Planned") {
        var visitor = await agents.visitor.searchByMeetingRefOrBadgeID({
          meetingRef: meetingReference,
          tenantName: tenant_name,
          depotCode: depot_code,
        });
        this.hasCheckedin = visitor.hasCheckedIn!;
        this.hasCheckedout = visitor.hasCheckedOut!;

        if (this.hasCheckedin == true && this.hasCheckedout == true) {
          this.CloseModalQR();
          toast.error(" Reference Number Already Used");
        } else {
          this.CloseModalQR();

          history.push(
            `/planned-visit/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );

          this.SearchVisitByQrCode(tenant_name, depot_code, meetingReference);
        }
      } else if (info.visittype == "Regular") {
        this.CloseModalQR();

        // history.push(
        //   `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        // );
        this.SearchRegularVisitorByQRCode(
          tenant_name,
          depot_code,
          meetingReference
        );
      } else if (info.visittype == "Partner") {
        var result = await agent.partner.findPartner(meetingReference);

        if (result.hasClockedIn == true && result.hasClockedOut == false) {
          this.openSignInValidationModal = true;
          this.openVisitorQRCodeOption = false;
        } else if (
          result.hasClockedIn == true &&
          result.hasClockedOut == true
        ) {
          this.CloseModalQR();

          history.push(
            `/partner/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );
          this.partnerstore.getPartner(meetingReference);
        } else {
          this.CloseModalQR();

          history.push(
            `/partner/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );
          this.partnerstore.getPartner(meetingReference);
        }
      } else {
        this.loading = false;
        this.CloseModalQR();
        toast.error("Wrong Reference Number Entered");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //QR REGULAR VISITOR

  @action SearchRegularVisitorByQRCode = async (
    tenantName: string,
    depotCode: string,
    RVReference: string
  ) => {
    try {
      const visitor = await agents.visitor.searchByQRCode({
        meetingRef: RVReference,
        tenantName: tenantName,
        depotCode: depotCode,
        //  PassCode:
      });
      var depot_code = this.rootStore.depotStore.depot?.id!;

      this.hasCheckedin = visitor.hasCheckedIn!;
      this.hasCheckedout = visitor.hasCheckedOut!;
      if (
        visitor.depotId != depot_code &&
        this.hasCheckedin == true &&
        this.hasCheckedout == false
      ) {
        this.DepotSignInWarningModal = true;
      } else if (this.hasCheckedin == true && this.hasCheckedout == false) {
        this.openSignInValidationModal = true;
        this.openVisitorQRCodeOption = false;
      } else if (this.hasCheckedin == true && this.hasCheckedout == true) {
        this.loading = false;
        history.push(
          `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );

        if (visitor) {
          this.openVisitorCodeOption = false;
          // this.dateofvisit = visitor.dateOfVisit,
          this.regVisitorRef = RVReference;
          this.signInVisitorName = visitor.firstName + " " + visitor.lastName;
          this.visitor = visitor;
          this.firstName = visitor.firstName;
          this.lastName = visitor.lastName;
          this.email = visitor.email;
          this.company = visitor.company;
          this.department = visitor.host.department;
          this.meetingWith = visitor.host.name;
          this.highlightInputBorderCarReg = true;
          this.highlightInputBorderTemp = true;
          this.findMeFlag = true;
          this.EnterTemperature = visitor.enterTemperature;
          this.signFlag = visitor.signFlag;
          this.badgeNumFlag = visitor.badgeNumFlag;
          this.carReg = visitor.carReg!;
        }

        this.temInstructions = true;
      } else {
        history.push(
          `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
        this.regVisitorRef = RVReference;
        this.signInVisitorName = visitor.firstName + " " +visitor.lastName;

        this.visitor = visitor;
        this.firstName = visitor.firstName;
        this.lastName = visitor.lastName;
        this.email = visitor.email;
        this.company = visitor.company;
        this.department = visitor.host.department;
        this.meetingWith = visitor.host.name;
        this.highlightInputBorderCarReg = true;
        this.highlightInputBorderTemp = true;
        this.findMeFlag = true;
        this.EnterTemperature = visitor.enterTemperature;
        this.signFlag = visitor.signFlag;
        this.badgeNumFlag = visitor.badgeNumFlag;
      }
    } catch (error) {
      this.clearVisitorData();
      ////////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      history.push(
        `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  @action SearchRegularVisitorByEmailAndPassCode = async (
    tenantName: string,
    depotCode: string
  ) => {
    try {
      const passCode: IPassCode = {
        PosOne: this._GlobalPassCodeArr[0].toString(),
        PosTwo: this._GlobalPassCodeArr[1].toString(),
        PosThree: this._GlobalPassCodeArr[2].toString(),
        CharPosOne: this.passCode_char_1_val,
        CharPosTwo: this.passCode_char_2_val,
        CharPosThree: this.passCode_char_3_val,
      };

      const visitor = await agents.visitor.searchByMeetingRefOrBadgeID({
        meetingRef: this.regVisitorRef,
        PassCode: passCode,
        tenantName: tenantName,
        depotCode: depotCode,
      });

      if (visitor) {
        this.visitor = visitor;
        this.firstName = visitor.firstName;
        this.lastName = visitor.lastName;
        this.email = visitor.email;
        this.company = visitor.company;
        this.department = visitor.host.department;
        this.meetingWith = visitor.host.name;
        this.highlightInputBorderCarReg = true;
        this.highlightInputBorderTemp = true;
        this.findMeFlag = true;
      }
    } catch (error) {
      this.clearVisitorData();
      this.GenerateRequiredCharactersForPassCode();
      //////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      history.push(
        `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  @action hideVirtualKeyboardVisible = async (ScreenSize: number) => {
    if (ScreenSize < 750) this.virtualKeyboardVisible = false;
  };

  @action clearVisitorData = async () => {
    try {
      this.unplannedProceedBtn = true;
      this.meetingRefOrBadgeNum = "";
      this.visitor = null;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.company = "";
      this.department = "";
      this.meetingWith = "";
      this.escort = "";
      this.carReg = "";
      this.passCode_char_1_index = "";
      this.passCode_char_2_index = "";
      this.passCode_char_3_index = "";
      this.passCode_char_1_val = "";
      this.passCode_char_2_val = "";
      this.passCode_char_3_val = "";
      this.depotBadgeNum = "";
      this.hr_1 = false;
      this.hr_2 = false;
      this.hr_3 = false;
      this.hr_4 = false;
      this.hr_5 = false;
      this.hr_proceedBtn = false;
      this.houseRulesWindow = false;
      this.sr_1 = false;
      this.sr_2 = "";
      this.sr_proceedBtn = false;
      this.securityRulesWindow = false;
      this.hsr_1 = "";
      this.hsr_2 = "";
      this.hsr_3 = "";
      this.hsr_4 = "";
      this.hsr_5 = "";
      this.hsr_6 = "";
      this.hsr_proceedBtn = false;
      this.healthSafetyWindow = false;
      this.or_1 = "";
      this.or_2 = "";
      this.or_proceedBtn = false;
      this.otherRulesWindow = false;
      this.openVisitorCodeOptionRegular = false;
      this.openVisitorCodeOption = false;
      this.QRCodeOptionModal = false;
      this.reason = "";
      this.temperature = "";
      this.regVisitorRef = "";
      this.highlightInputBorderCarReg = false;
      this.highlightInputBorderTemp = false;
      this.temInstructionsFlag1 = true;
      this.findMeFlag = false;
    } catch (error) {
      throw error;
    }
  };

  @action VisitorCheckIn_plannedVisit = async () => {
    try {
      const data: IVisitorModel = {
        carReg: this.carReg,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        meetingWith: this.meetingWith,
        visitorRef: this.meetingRefOrBadgeNum,
        depotBadgeNum: this.depotBadgeNum,
        depot: this.rootStore.depotStore.depot?.id!,
        temperature: this.temperature,
      };
      this.loading= true;
      const response = await agent.visitor.checkIn(data);
      this.loading=false;
      if (response) {
        this.ShowSignNotice();
        history.push(
          `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      } else {
        // do something else
      }
    } catch (error) {
      //////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };
  @action VisitorCheckIn_unplannedVisit = async () => {
    try {
      const data: IVisitorModel = {
        carReg: this.carReg,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        meetingWith: this.meetingWith,
        depotBadgeNum: this.depotBadgeNum,
        reasonForVisit: this.reason,
        Company: this.company,
        depot: this.rootStore.depotStore.depot?.id!,
        temperature: this.temperature,       
      };
      this.signInVisitorName = this.firstName+ ' ' + this.lastName;
      console.log(this.firstName, 'unplannedfirstname')
      console.log(this.lastName, 'unplannedlastname')

      this.loading = true;
      const response = await agent.visitor.unplannedVisitCheckIn(data);
      this.loading = false;
      if (response) {
        this.ShowSignNotice();
        history.push(
          `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      } else {
        // do something else
      }
    } catch (error) {
      //////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };
  @action VisitorCheckIn_regularVisitor = async () => {
    try {
      const data: IVisitorModel = {
        carReg: this.carReg,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        meetingWith: this.meetingWith,
        visitorRef: this.regVisitorRef,
        depotBadgeNum: this.depotBadgeNum,
        depot: this.rootStore.depotStore.depot?.id!,
        temperature: this.temperature,
      };
      this.loading = true;
      const response = await agent.visitor.checkIn(data);
      this.loading= false;
      if (response) {
        history.push(
          `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${
            this.rootStore.depotStore.depot?.code
          }/${"regular"}`
        );
      } else {
        // do something else
      }
    } catch (error) {
      //////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;
      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  @action VisitorCheckInQR_plannedVisit = async () => {
    try {
      const data: IVisitorModel1 = {
        carReg: this.carReg,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        meetingWith: this.meetingWith,
        visitorRef: this.meetingRefOrBadgeNum,
        // depotBadgeNum: this.depotBadgeNum,
        depot: this.rootStore.depotStore.depot?.id!,
        temperature: this.temperature,
      };
      this.loading = true;
      const response = await agent.visitor.checkIn1(data);
      this.loading = false;
      if (response) {
        this.ShowSignNotice();
        //this.houseRulesWindow = true;
        history.push(
          `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      } else {
        // do something else
      }
    } catch (error) {
      //////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  @action VisitorCheckInQR_regularVisitor = async () => {
    try {
      const data: IVisitorModel1 = {
        carReg: this.carReg,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        meetingWith: this.meetingWith,
        visitorRef: this.regVisitorRef,
        //depotBadgeNum: this.depotBadgeNum,
        depot: this.rootStore.depotStore.depot?.id!,
        temperature: this.temperature,
      };
      this.loading = true;
      const response = await agent.visitor.checkIn1(data);
      this.loading = false;


      if (response) {
        this.checkInWindow = true;
        setTimeout(this.closeCheckInMessage, 4000);
        if (!this.checkInWindow)
          history.push(
            `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );
      }
      // if (response) {
      //   this.ShowSignNotice();
      //   //this.houseRulesWindow = true;
      //   history.push(
      //     `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${
      //       this.rootStore.depotStore.depot?.code
      //     }/${"regular"}`
      //   );
      // }
      else {
        // do something else
      }
    } catch (error) {
      //////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;
      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  @action VisitorCheckInQRHouserulesturnedOn_regularVisitor = async () => {
    try {
      const data: IVisitorModel1 = {
        carReg: this.carReg,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        meetingWith: this.meetingWith,
        visitorRef: this.regVisitorRef,
        //depotBadgeNum: this.depotBadgeNum,
        depot: this.rootStore.depotStore.depot?.id!,
        temperature: this.temperature,
      };
      this.loading = true;
      const response = await agent.visitor.checkIn1(data);
      this.loading = false;

      if (response) {
        this.houseRulesWindow = true;
        // history.push(
        //   `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${
        //     this.rootStore.depotStore.depot?.code
        //   }/${"regular"}`
        // );
        //this.houseRulesWindow = true;
        //this.ShowSignNotice();
        //this.houseRulesWindow = true;
      } else {
        // do something else
      }
    } catch (error) {
      //////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;
      setTimeout(this.ClearErrorMessage, 3000);
    }
  };
  @action OpenConditionsWindow = async (visitType: string) => {
    try {
      this.houseRulesWindow = false;
      this.securityRulesWindow = false;
      this.securityRulesWindow = false;
      this.otherRulesWindow = false;
      this.visitPath = history.location.pathname;
      switch (visitType) {
        case "unplanned-visitor":
          await this.UnplannedVisitHostNotification(this.reason);
          break;
        case "regular-visitor":
          await this.RegularVisitHostNotification(this.regVisitorRef);
          break;
        case "planned-visitor":
          await this.PlannedVisitHostNotification(this.meetingRefOrBadgeNum);
          break;
        default:
          break;
      }
      console.log(this.badgeNumFlag, "Imp ");
      console.log(this.signFlag, "IMp1 ");


      if (visitType !== "regular-visitor") {
        this.houseRulesWindow = true;
      } else if (
        this.badgeNumFlag == false &&
        this.signFlag == false &&
        visitType == "regular-visitor"
      ) {
        //this.houseRulesWindow = true;
        this.VisitorCheckInQR_regularVisitor();
      } else if (
        this.badgeNumFlag == true &&
        this.signFlag == false &&
        visitType == "regular-visitor"
      ) {
        history.push(
          `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      } else if (
        this.badgeNumFlag == false &&
        this.signFlag == true &&
        visitType == "regular-visitor"
      ) {
        this.VisitorCheckInQRHouserulesturnedOn_regularVisitor();
      } else if (
        this.badgeNumFlag == true &&
        this.signFlag == true &&
        visitType == "regular-visitor"
      ) {
        this.houseRulesWindow = true;
      } else {
        history.push(
          `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action PlannedVisitHostNotification = async (meetingRef: string) => {
    let data: IEmailNotification = {
      depot: this.rootStore.depotStore.depot?.id!,
      depotcode: this.rootStore.depotStore.depot?.code!,

      meetingRef: meetingRef,
      tenant: this.rootStore.depotStore.depot?.tenantName,
    };
    try {
      await agent.visitor.sendEmail_plannedVisit(data);
    } catch (error) {
      throw error;
    }
  };

  @action RegularVisitHostNotification = async (meetingRef: string) => {
    let data: IEmailNotification = {
      depot: this.rootStore.depotStore.depot?.id!,
      depotcode: this.rootStore.depotStore.depot?.code!,

      meetingRef: meetingRef,
      tenant: this.rootStore.depotStore.depot?.tenantName,
    };
    try {
      await agent.visitor.sendEmail_regularVisit(data);
    } catch (error) {
      throw error;
    }
  };

  @action UnplannedVisitHostNotification = async (reason: string) => {
    let data: IEmailNotification = {
      depot: this.rootStore.depotStore.depot?.id!,
      depotcode: this.rootStore.depotStore.depot?.code!,
      reason: reason,
      name: this.firstName + " " + this.lastName,
      company: this.company,
    };
    try {
      await agent.visitor.sendEmail_unplannedVisit(data);
    } catch (error) {
      throw error;
    }
  };

  @action DennyConditions = async () => {
    this.houseRulesWindow = false;
    this.securityRulesWindow = false;
    this.otherRulesWindow = false;
    this.healthSafetyWindow = false;
  };

  @action VisitorSubmitBadgeNumber = async () => {
    if (this.visitPath.includes("/unplanned-visit/"))
      await this.VisitorCheckIn_unplannedVisit();
    if (this.visitPath.includes("/planned-visit/"))
      await this.VisitorCheckIn_plannedVisit();
    if (this.visitPath.includes("/regular-visitor/"))
      await this.VisitorCheckIn_regularVisitor();
  };

  @action AcceptConditions = async (name: string) => {
    switch (name) {
      case "houseRules":
        this.houseRulesWindow = false;
        // this.securityRulesWindow = true;
        //this.otherRulesWindow = false;
        this.healthSafetyWindow = true;
        break;
      // case "SecurityRules":
      //   runInAction(() => {
      //     this.houseRulesWindow = false;
      //     this.securityRulesWindow = false;
      //     this.healthSafetyWindow = true;
      //     this.otherRulesWindow = false;
      //   });
      //   break;
      case "HeathSafetyRules":
        this.houseRulesWindow = false;
        // this.securityRulesWindow = false;
        // this.otherRulesWindow = false;
        this.healthSafetyWindow = false;
        console.log(this.badgeNumFlag, "one ");
        console.log(this.signFlag, "one ");

        if (this.badgeNumFlag == true && this.signFlag == true) {
          history.push(
            `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
          );
        } else {
          this.checkInWindow = true;
          setTimeout(this.closeCheckInMessage, 4000);
          if (!this.checkInWindow)
            history.push(
              `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
            );
        }

        //   break;
        // case "OtherRules":
        //   runInAction(() => {
        //     this.houseRulesWindow = false;
        //     this.securityRulesWindow = false;
        //     this.otherRulesWindow = false;
        //     this.healthSafetyWindow = false;

        // history.push(
        //   `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        // );

        // this. VisitorCheckInQRHouserulesturnedOn_regularVisitor()

        //   });
        //this.OpenConditionsWindow(name);

        // history.push(
        //   `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        // );
        break;
      default:
        history.push(
          `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );

        break;
    }
  };


  @action AcceptConditionsUnplanned = async (name: string) => {
    switch (name) {
      case "houseRules":
        this.houseRulesWindow = false;
        // this.securityRulesWindow = true;
        //this.otherRulesWindow = false;
        this.healthSafetyWindow = true;
        break;
      // case "SecurityRules":
      //   runInAction(() => {
      //     this.houseRulesWindow = false;
      //     this.securityRulesWindow = false;
      //     this.healthSafetyWindow = true;
      //     this.otherRulesWindow = false;
      //   });
      //   break;
      case "HeathSafetyRules":
        this.houseRulesWindow = false;
        // this.securityRulesWindow = false;
        // this.otherRulesWindow = false;
        this.healthSafetyWindow = false;



        history.push(
          `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );

        // this. VisitorCheckInQRHouserulesturnedOn_regularVisitor()

        //   });
        //this.OpenConditionsWindow(name);

        // history.push(
        //   `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        // );
        break;
      default:
        history.push(
          `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );

        break;
    }
  };

  @action AcceptConditionsPlanned = async (name: string) => {
    switch (name) {
      case "houseRules":
        this.houseRulesWindow = false;
        // this.securityRulesWindow = true;
        //this.otherRulesWindow = false;
        this.healthSafetyWindow = true;
        break;
      // case "SecurityRules":
      //   runInAction(() => {
      //     this.houseRulesWindow = false;
      //     this.securityRulesWindow = false;
      //     this.healthSafetyWindow = true;
      //     this.otherRulesWindow = false;
      //   });
      //   break;
      case "HeathSafetyRules":
        this.houseRulesWindow = false;
        // this.securityRulesWindow = false;
        // this.otherRulesWindow = false;
        this.healthSafetyWindow = false;



        history.push(
          `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );

        // this. VisitorCheckInQRHouserulesturnedOn_regularVisitor()

        //   });
        //this.OpenConditionsWindow(name);

        // history.push(
        //   `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        // );
        break;
      default:
        history.push(
          `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );

        break;
    }
  };
  @action ManualCheckOut = async (
    depotBadge: string,
    depotId: string,
    visitorId: string,
    sendNotification: boolean,
    tenantId?: string
  ) => {
    try {
      const response = await agent.visitor.checkout({
        visitorAttendanceId: visitorId,
        manualCheckout: true,
        depotBadge: depotBadge,
        sendNotification: sendNotification,
        depotId: depotId,
      });

      if (response) {
        toast.success("Visitor was checked-out");
        //window.location.reload();
       // history.push("/reports");

       this.reportStore.VisitorReportDataDateRange = [];
       this.reportStore.visitorsDateRangeEnvelope = null;
       // window.location.reload();
        //history.push("/tenants");
        if (tenantId)
          this.rootStore.tenantStore.findTenant({ tenantId: tenantId });
        this.rootStore.modalStore.closeModal();
        //history.push("/dashboard");
      }
    } catch (error) {
      throw error;
    }
  };

  @action VisitorCheckOut = async (badgeNum: string, depotId: string) => {
    try {
      this.loading = true;
      const visitor = await agent.visitor.getVisitorName(badgeNum, depotId);
      this.loading = false;

      if (visitor) {
        this.visitorName = visitor.name;
        visitor.depotbadgenum = this.depotBadgeNum;
        const response = await agent.visitor.checkout({
          visitorAttendanceId: visitor.id,
          manualCheckout: false,
          depotId: depotId,
          depotBadge: badgeNum,
        });
        if (response) {
          this.checkoutWindow = true;

          setTimeout(this.closeCheckoutMessage, 4000);
        } else {
          toast.info("Batch number not in Use.");
        }
      }else{
        this.BatchNumberMessage = true;
      }
    } catch (error) {
      //////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  @action VisitorQRCheckOut = async (
    meetingReference: string,
    depotId: string
  ) => {
    try {
      this.loading = false;
      const visitor = await agent.visitor.QRgetVisitorName(
        meetingReference,
        depotId
      );
      this.hasCheckedin = visitor.hasCheckedIn;
      this.hasCheckedout = visitor.hasCheckedOut;

      if (
        this.hasCheckedin == true &&
        this.hasCheckedout == true &&
        visitor.visitType == "Planned"
      ) {
        toast.error("Reference Number already used");
      } else if (this.hasCheckedin == true && this.hasCheckedout == true) {
        this.QRCodeOptionModal = false;
        this.openSignOutValidationModal = true;
      } else if (this.hasCheckedin == true && this.hasCheckedout == false) {
        if (visitor) {
          this.visitorName = visitor.name;
          visitor.depotbadgenum = this.depotBadgeNum;
          this.hasCheckedout = visitor.hasCheckedOut;
          //this.hasCheckedin = visitor.hasCheckedIn;
          this.loading = true;
          const response = await agent.visitor.QRcheckout({
            visitorAttendanceId: visitor.id,
            manualCheckout: false,
            depotId: visitor.depotId!,
            depotBadge: meetingReference,
          });
          this.loading = false;

          if (response) {
            this.QRCodeOptionModal = false;
            this.checkoutWindow = true;

            setTimeout(this.closeCheckoutMessage, 4000);
          } else {
            toast.error("Invalid Reference Number");
          }
        }
      }
    } catch (error) {
      //////this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  @action closeCheckoutMessage = () => {
    this.checkoutWindow = false;

    if (!this.checkoutWindow) {
      this.clearVisitorData();
      history.push(
        `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
    }
  };
  @action closeCheckInMessage = () => {
    this.checkInWindow = false;

    if (!this.checkInWindow) {
      this.clearVisitorData();
      history.push(
        `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
    }
  };

  @action ClearErrorMessage = async () => {
    this.errors = "";
  };
  @action ChangeEnterBadgeNum = async (depotBadgeNum: string) => {
    this.depotBadgeNum = depotBadgeNum;
  };

  @action PrintHouseRulesClick = async (signBlob: any) => {
    const response = await agents.submitSign.formSignature({
      sign: signBlob,
      depotBadge: this.depotBadgeNum,
      depot: this.rootStore.depotStore.depot?.id!,
    });

    this.checkInWindow = true;
    if (response) {
      setTimeout(this.closeCheckInMessage, 4000);
      if (!this.checkInWindow)
        history.push(
          `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
    }
  };

  @action ShowSignNotice = async () => {
    this.scrollDownFlag = true;

    setTimeout(this.CloseSignNotice, 3000);
    if (!this.checkInWindow)
      history.push(
        `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
  };
  @action CloseSignNotice = async () => {
    this.scrollDownFlag = false;
  };
  @action CloseModalHR = async () => {
    this.houseRulesWindow = false;
  };
  @action CloseModalSR = async () => {
    this.securityRulesWindow = false;
  };
  @action CloseModalHSR = async () => {
    this.healthSafetyWindow = false;
  };
  @action CloseModalOR = async () => {
    this.otherRulesWindow = false;
  };
  @action CloseModalQR = async () => {
    this.openVisitorQRCodeOption = false;
    //this. = false;
  };

  @action CloseModalQRcheckout = async () => {
    this.QRCodeOptionModal = false;
  };

  @action refreshPage = async () => {
    history.push(
      `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
    );
  };
  @action validateForm = async () => {
    try {
      if (
        this.firstName.length <= 0 &&
        this.lastName.length <= 0 &&
        this.meetingWith.length <= 0 &&
        this.email.length <= 0 &&
        this.company.length <= 0 &&
        //this.temperature.length <= 0 &&
        this.carReg.length <= 0
      ) {
        this.unplannedProceedBtn = false;
        this.formIsValid = false;
      }
      // if(this.temperature == "34" || "35" || "36" ||  "37" || "38" || "39" )
      // {
      //   this.formIsValid =true;

      // }
      // else{
      //   this.formIsValid = false;
      //   toast.info("Please Wait at the reception");

      // }
      else this.formIsValid = true;
    } catch (error) {
      throw error;
    }

    this.disableFindMeBtm =
      this.passCode_char_1_val.length === 1 &&
      this.passCode_char_2_val.length === 1 &&
      this.passCode_char_3_val.length === 1 &&
      this.regVisitorRef.length > 0;
  };

  @action validateRegularform = async () => {
    try {
      if (
        //this.temperature.length > 0 &&
        this.firstName.length > 0 &&
        this.lastName.length > 0 &&
        this.meetingWith.length > 0 &&
        this.email.length > 0 &&
        this.company.length > 0 &&
        // this.temperature.startsWith("3") &&
        //this.temperature.length >0&&
        this.carReg.length > 0
      ) {
        this.regularvisitorbtn = false;
      } else {
        this.regularvisitorbtn = true;
      }
    } catch (error) {
      throw error;
    }
  };

  @action TempvalidationRV = async () => {
    try {
      this.OpenConditionsWindow("regular-visitor");

      // if (this.temperature > "39") {
      //   this.clearVisitorData();
      //   this.TemperatureValidationModal = true;
      // } else if (this.temperature < "33") {
      //   this.clearVisitorData();
      //   this.TemperatureValidationModal = true;
      // } else if (this.temperature == "0.0"){
      //   this.OpenConditionsWindow("regular-visitor");
      // }
    } catch (error) {
      throw error;
    }
  };

  @action TempvalidationPlanned = async () => {
    try {

      this.OpenConditionsWindow("planned-visitor");

      // if (this.temperature > "39") {
      //   this.clearVisitorData();
      //   this.TemperatureValidationModal = true;
      // } else if (this.temperature < "33") {
      //   this.clearVisitorData();
      //   this.TemperatureValidationModal = true;
      // } else if (this.temperature == "0.0") {
      //   this.OpenConditionsWindow("planned-visitor");
      // }
    } catch (error) {
      throw error;
    }
  };

  @action TempvalidationUnplanned = async () => {
    try {

      this.OpenConditionsWindow("unplanned-visitor");

      // if (this.temperature > "39") {
      //   this.clearVisitorData();
      //   this.TemperatureValidationModal = true;
      // } else if (this.temperature < "33") {
      //   this.clearVisitorData();
      //   this.TemperatureValidationModal = true;
      // } else if (this.temperature == "0.0") {
      //   this.OpenConditionsWindow("unplanned-visitor");
      // }
    } catch (error) {
      throw error;
    }
  };
  @action unplannedValidateForm = async () => {
    try {
      if (
       // this.temperature.length > 0 &&
        this.firstName.length > 0 &&
        this.lastName.length > 0 &&
        this.email.length > 0 &&
        this.company.length > 0 &&
        this.carReg.length > 0 &&
        this.reason.length > 0
      ) {
        this.unplannedProceedBtn = false;
      } else {
        this.unplannedProceedBtn = true;
      }
    } catch (error) {
      throw error;
    }
  };
}
