import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Label } from "semantic-ui-react";
import { DateTimePicker } from "react-widgets";

interface IProps extends FieldRenderProps<Date, HTMLElement>, FormFieldProps {}

const DateInputNRV: React.FC<IProps> = ({
  input,
  width,
  date = false,
  time = false,
  placeholder,
  meta: { touched, error },
}) => {
  return (
    <Form.Field
      className="fix-position-moz-DateTimePicker"
      error={touched && error}
      width={width}
    >
      <DateTimePicker
        format="dd/MM/yyyy"
        min={new Date(Date.now())}
        placeholder={placeholder}
        value={input.value || null}
        onChange={input.onChange}
        //onBlur={input.onBlur}
        onKeyDown={(e) => e.preventDefault()}
        date={date}
        time={time}
      />
      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default DateInputNRV;
