import { combineValidators, isRequired } from "revalidate";
import React, { useContext, useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import {IBookedVisitors, IEditRV} from "../../../models/VisitorModel";
import { observer } from "mobx-react-lite";
import { Button, Form, Grid, Checkbox, Header, Icon, Segment } from "semantic-ui-react";
import {RootStoreContext} from "../../../stores/rootStore";
import RemoveRegularVisitor from './RemoveRV';
import TextInput from "../../../common/form/TextInput";
import SelectInput from "../../../common/form/SelectInput";
import ErrorMessage from "../../layout/ErrorMessage";
import { FORM_ERROR } from "final-form";
import { values } from "mobx";

const validate = combineValidators(
    {
        hostname: isRequired("hostname"),
        hostemail: isRequired("hostemail"),
        department: isRequired("department"),
        //hostid: isRequired("hostid")

        

    }
);

interface IProps{
    Hid: IEditRV;
    isViewDetails:boolean
}

const EditHost: React.FC<IProps> = ({ Hid, isViewDetails}) =>
{

    const rootStore = useContext(RootStoreContext);
    const { HostdetailsUpdate,handleHostUpdateClick,resetUpdateHost,EditHostinfo}  = rootStore.rvStore;

    const handleEditHost = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        data: IEditRV
      ) => {
        event.preventDefault();
        handleHostUpdateClick();
      };
    
      useEffect(() => {
  
        return () => {
          resetUpdateHost();
        };
      }, [resetUpdateHost]);

    
    return(


        <div>
   
        <Grid centered className ="HostDetailsGrid">
        <Grid.Column width={16} stretched ={true}>
        <Header as="h4" attached="top">
            {isViewDetails ? (
              <div>
                Edit <u>{Hid?.name}</u>  Host Details
              </div>
            ) : (
              <div>
                View <u>{Hid?.name}</u>  Host Details
              </div>
            )}

          </Header>
          <Segment clearing>
            <FinalForm
            
              onSubmit={(values: IEditRV) =>
                EditHostinfo(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }))
              }
              initialValues={Hid}
              validate={validate}  
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error loading={submitting}>

                  <Form.Group widths="equal">
                  {/* <Field
                        label="Host Id"
                        name="hostid"
                        placeholder="Host Id"
                        component={TextInput}
                        readOnly={true}
                        defaultValue = {Hid?.hostid}
                      /> */}
                     <Field
                        label="Host Name"
                        name="hostname"
                        placeholder="Host Name"
                        component={TextInput}
                        readOnly={!HostdetailsUpdate}
                        defaultValue = {Hid?.hostname}
                      />
                      <Field
                        label="Host Email"
                        name="hostemail"
                        placeholder="Host Email Address"
                        component={TextInput}
                       readOnly={!HostdetailsUpdate}
                       defaultValue = {Hid?.hostemail}
                      />   
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Field
                      label="Department"
                      name="department"
                      placeholder="Department"
                      component={TextInput}
                      readOnly={!HostdetailsUpdate}
                      defaultValue = {Hid?.department}
                    />
                   
                  </Form.Group>


                  {HostdetailsUpdate ? (
                    <Button
                      fluid
                      icon="save"
                      loading={submitting}
                      disabled={(invalid && !dirtySinceLastSubmit)}
                      positive
                      labelPosition="left"
                      content="Save"
                    />
                  ) : (
                    <Button
                      fluid
                      icon="edit"
                      loading={submitting}
                      // disabled={
                      //   (invalid && !dirtySinceLastSubmit) || pristine
                      // }
                      primary
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleEditHost(event, Hid!)}
                      labelPosition="left"
                      content="Update"
                    />
                  )}
                    {submitError && !dirtySinceLastSubmit && (
                    <ErrorMessage error={submitError} />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>

        </div>
    );

}
export default observer(EditHost)